import React, { type Dispatch, type SetStateAction, useState } from 'react';
import Button from '../../Button';
import Icon from '../../Icon';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import { t } from 'i18next';

interface Props {
  clickable: boolean;
  setClickable: Dispatch<SetStateAction<boolean>>;
  updateGasStationLocation: (setSaving: Dispatch<SetStateAction<boolean>>,
    setClickable: Dispatch<SetStateAction<boolean>>) => void;
}

const TransactionLocationUpdate: React.FC<Props> = ({ clickable, setClickable, updateGasStationLocation }) => {
  const [saving, setSaving] = useState<boolean>(false);
  return (
    <>
      <Button
          outline
          className="!mt-0 !px-4 flex items-center gap-x-1"
          type="button"
          onClick={() => {
            setClickable(true);
          }}
      >
          <Icon name="location-dot" />
          {t('Fix Gas Station')}
      </Button>
      {clickable &&
        <>
          <div className="absolute bottom-5 flex flex-col gap-3">
            <p className="text-sm max-w-[200px]">{t('Click the map at the gas station\'s actual location.')}</p>
            <div className="flex justify-start align-middle items-center w-full gap-2">
              <Button
                outline
                className="!mt-0 !px-4 flex items-center gap-x-1"
                type="button"
                onClick={() => {
                  setSaving(true);
                  updateGasStationLocation(setSaving, setClickable);
                }}
              >
                <Icon name="save-circle-arrow-right" />
                {t('Save')}
              </Button>
              <Button
                  outline
                  className="!mt-0 !px-4 flex items-center gap-x-1"
                  type="button"
                  onClick={() => {
                    setClickable(false);
                  }}
              >
                <Icon name="cancel" />
                {t('Cancel')}
              </Button>
            </div>
            {saving && <Box sx={{ width: '75%' }}>
              <LinearProgress sx={{
                backgroundColor: 'transparent',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#44A5FF'
                }
              }}
              />
            </Box>}
          </div>
        </>}
    </>
  );
};

export default TransactionLocationUpdate;
