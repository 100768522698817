import React from 'react';
import { mainLogo } from '../helpers/CustomerAssets';

type IStyle = Record<string, number | string | null>;

interface Props {
  style?: IStyle;
  className?: string | null;
}

const Logo: React.FC<Props> = ({ style, className = null }: any) => {
  return (
    <img
      src={mainLogo.value}
      alt="Logo"
      style={style}
      className={className ? `${className}` + ' rounded-lg' : ''}
    />
  );
};

export default Logo;
