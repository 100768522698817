import React, { useEffect, useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { useSpringValue, animated, type SpringValue } from '@react-spring/web';
import { createPortal } from 'react-dom';
import Icon from './Icon';
import classNames from 'classnames';
import { appState, useSignal } from '../..';

interface Props {
  setDraggable?: Dispatch<SetStateAction<boolean>>;
  show?: boolean;
  maxWidth?: string | number;
  maxHeight?: string | number;
  minHeight?: string | number;
  header?: React.ReactNode;
  children: React.ReactNode;
  onHide: () => void;
  className?: string;
  customZIndex?: number;
}

let modalCount = 0;

const Modal: React.FC<Props> = ({
  show = false,
  maxWidth = '20rem',
  maxHeight,
  minHeight,
  header,
  children,
  onHide,
  className,
  customZIndex = 1000
}) => {
  const updatedAccountId = useSignal(appState.accountId);
  const [isShown, setIsShown] = useState<boolean>(show);
  const opacity: SpringValue<number> = useSpringValue(isShown ? 1 : 0);
  const zIndex: SpringValue<number> = useSpringValue(isShown ? customZIndex : -1);
  useEffect(() => {
    void (async () => {
      if (isShown) {
        zIndex.set(customZIndex);
        await opacity.start(1);
      } else {
        await opacity.start(0);
        zIndex.set(-1);
        onHide();
      }
    })();
  }, [isShown]);

  const hideModal = (): void => {
    setIsShown(false);
  };

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  useEffect(() => {
    if (isShown) {
      modalCount += 1;
    } else if (modalCount > 0) {
      modalCount -= 1;
    }
  }, [isShown]);

  // Checks every render the total count of modals that are open to hide the background scrolling
  useEffect(() => {
    if (modalCount === 0) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  });

  const close = (e: any): void => {
    if (e.keyCode === 27) {
      setIsShown(false);
    }
  };

  useEffect(() => {
    window?.addEventListener('keydown', close);

    return () => {
      window?.removeEventListener('keydown', close);
    };
  }, []);

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      setIsShown(false);
    } else {
      didMount.current = true;
    }
  }, [updatedAccountId]);

  return createPortal(
    <animated.div
      className={classNames(
        'fixed inset-0 bg-blur-1/80 backdrop-blur-[1.5px] flex items-center justify-center',
        className
      )}
      style={{
        opacity,
        zIndex
      }}
    >
      <div
        className="absolute inset-0"
        onClick={hideModal}
      />
      <div
        className="bg-dark-3 p-4 rounded-lg text-white min-w-[20rem] w-full flex-grow relative z-20"
        style={{
          maxWidth,
          maxHeight,
          minHeight
        }}
      >
        <div className="flex items-center justify-between">
          <div className="text-base w-full">{header}</div>
          <button
            onClick={hideModal}
            className="text-custom-gray-normal hover:text-custom-gray-normal/20"
          >
            <Icon
              name="close"
              className="transition"
            />
          </button>
        </div>
        {children}
      </div>
    </animated.div>,
    document.body
  );
};

export default Modal;
