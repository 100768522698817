import React from 'react';
import Icon from './Icon';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

interface Props {
  icon: IconName;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const IconButton: React.FC<Props> = ({
  icon,
  className = '',
  disabled,
  onClick = () => { }
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames('bg-dark-2 rounded-xl h-[36px] w-[36px] shadow-md text-custom-blue-normal hover:text-custom-blue-hover', className)}
      disabled={disabled}
    >
      <Icon name={icon} />
    </button>
  );
};

export default IconButton;
