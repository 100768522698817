/* eslint-disable @typescript-eslint/no-floating-promises */
import classNames from 'classnames';
import { animated, type SpringValue, useSpringValue } from '@react-spring/web';
import React, { useEffect } from 'react';

interface Props {
  label?: string;
  labelPosition?: 'right' | 'left'; // More positions todo when needed
  checked: boolean;
  onClick: (value: boolean) => void;
}

const Switch: React.FC<Props> = ({
  label,
  labelPosition,
  checked,
  onClick
}) => {
  const x: SpringValue<number> = useSpringValue(checked ? 16 : 0);

  useEffect(() => {
    (async () => {
      await x.start(checked ? 16 : 0);
    })();
  }, [checked]);

  return (
    <div
      className={
        labelPosition === 'right'
          ? 'flex flex-row-reverse justify-end items-center gap-x-2' // Adjust gap for right label
          : labelPosition === 'left'
            ? 'flex flex-row justify-start items-center gap-x-2' // Adjust gap for left label
            : 'flex items-center' // Default alignment, no extra gap
      }
    >
      {label && (
        <p className={labelPosition === 'right' ? 'ml-2 text-xs' : 'mr-2 text-xs'}> {/* Adjust margin dynamically */}
          {label}
        </p>
      )}
      <button
        className="relative h-[20px] w-[36px]"
        type="button"
        onClick={() => {
          onClick(!checked);
        }}
      >
        <div className={classNames('transition h-[14px] w-[36px] bg-custom-gray-normal rounded-full absolute top-1/2 -translate-y-1/2', checked && '!bg-custom-blue-normal !bg-opacity-50')} />
        <animated.div
          className="h-[20px] w-[20px] absolute left-0 top-0 z-10"
          style={{
            x
          }}
        >
          <div className={classNames('transition rounded-full h-full w-full', checked ? 'bg-custom-blue-normal' : 'bg-white')}></div>
        </animated.div>
      </button>
    </div>
  );
};

export default Switch;
