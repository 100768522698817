/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { type JSXElementConstructor, type ReactElement } from 'react';
import RCDropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import './DropdownV2.scss';

type Menu = ReactElement<any, string | JSXElementConstructor<any>> | (() => ReactElement<any, string | JSXElementConstructor<any>>) | undefined;
type Children = ReactElement<any, string | JSXElementConstructor<any>>;

interface Props {
  menu: Menu;
  children: Children;
}

const DropdownV2: React.FC<Props> = ({
  menu,
  children
}) => {
  return (
    <RCDropdown
      trigger={['click']}
      overlay={menu}
      animation="slide-up"
    >
      {children}
    </RCDropdown>
  );
};

export default DropdownV2;
