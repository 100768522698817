import React, { Suspense } from 'react';
import { appState, logoutChild } from '../../../index';
import '../../../index.css';
import Button from '../Button';
import { AUTHORIZED_ROUTES } from '../../../enums/ROUTES';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const ChildBanner: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Suspense fallback="loading...">
      <div className="child-banner">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ color: 'white', marginRight: '20px' }}>{t('You are logged in as ')} <strong>{appState.accountName.value}</strong></p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="button"
              style={{ marginTop: '0' }}
              onClick={() => {
                navigate(AUTHORIZED_ROUTES.TRANSACTIONS);
                logoutChild();
              }}
            >
              {t('Logout')}
            </Button>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default withTranslation()(ChildBanner);
