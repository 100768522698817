import React, { useEffect, useState, useCallback } from 'react';
import Icon from '../../../ui/shared/Icon';
import { DataGrid, type GridInitialState, type GridColDef, GridToolbar } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import { appState, useSignal, handleDataGridStateChange } from '../../../index';
import Tooltip from '@mui/material/Tooltip';
import { iconColorMap, iconTypeMapping } from '../../../helpers/types';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import FillingInfoDetailsModal from '../../../ui/shared/Modals/filling-info/FillingInfoDetails.modal';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarPickerModal from '../../../ui/shared/Modals/CalendarPicker.modal';
import { openToast } from '../../../helpers/toast';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import SearchButton from '../../../ui/shared/SearchButton';
import { type UserSettings } from '../../../types/user.d';
import FuelingEfficiencySettingsModal from '../../../ui/shared/Modals/filling-info/FuelingEfficiencySettings.modal';
import { type DayRange } from '@hassanmojab/react-modern-calendar-datepicker';
import { isValidCell } from '../../../helpers/columns';

const FillingInfo: React.FC = () => {
  const updatedAccountId = useSignal(appState.accountId);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [transactionIds, setTransactionIds] = useState([]);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [defaultPeriod, setDefaultPeriod] = useState<DayRange | null>(null);

  const calculateDefaultDayRange = (defaultDays: string): void => {
    const periodInSeconds = parseInt(defaultDays) * 24 * 60 * 60;
    const timeNowInSeconds = Math.floor(Date.now() / 1000);
    const fromDate = new Date((timeNowInSeconds - periodInSeconds) * 1000);
    const toDate = new Date(timeNowInSeconds * 1000);
    setDefaultPeriod({
      from: {
        year: fromDate.getFullYear(),
        month: fromDate.getMonth(),
        day: fromDate.getDate()
      },
      to: {
        year: toDate.getFullYear(),
        month: toDate.getMonth(),
        day: toDate.getDate()
      }
    });
  };

  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const params = {
        user_id: appState.userId.value,
        account_id: appState.accountId.value
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get-filling-info`, params);
      const userSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${appState.userId.value}(eq)`);
      setUserSettings(userSettingsResponse.data.data[0]);
      calculateDefaultDayRange(userSettingsResponse.data.data[0].default_fueling_efficiency_days);
      setData(response.data.names);
    } catch (error) {
      console.error('Error fetching data:', error);
      openToast({
        type: 'error',
        label: 'Error fetching fueling efficiency data',
        autoClose: 2000,
        theme: 'dark'
      });
    }
    setLoading(false);
  }, [appState]);

  useEffect(() => {
    fetchData();
  }, [updatedAccountId, fetchData]);

  useEffect(() => {
    let newFilteredData;
    if (searchQuery) {
      newFilteredData = data.filter((item: any) =>
        item.display_name.includes(searchQuery) ||
        item.formatted_average_filling_time.includes(searchQuery) ||
        item.average_filling_percent.toString().includes(searchQuery)
      );
    } else {
      newFilteredData = data;
    }
    setFilteredData(newFilteredData);
  }, [searchQuery, data]);
  interface HeaderTooltipProps {
    title: string;
    field: string;
  }

  const HeaderTooltip: React.FC<HeaderTooltipProps> = ({ title, field }) => {
    const iconName: IconName = iconTypeMapping[field];
    const iconColor = iconColorMap[iconName as keyof typeof iconColorMap];

    return (
      <Tooltip title={t(title)}>
        <div className={`flex items-center ${iconColor}`}>
          {iconName ? <Icon name={iconName} /> : <span>{t(title)}</span>}
        </div>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Driver'),
      width: 250,
      renderCell: (params: any) => (
        <button
          type="button"
          className="text-custom-blue-normal hover:text-custom-blue-hover"
          onClick={() => {
            setIsDetailsModalOpen(true);
            setTransactionIds(params.row.transaction_ids);
          }}
        >
          {!isValidCell(params.row.name) ? 'No Driver Name' : params.row.name}
        </button>
      )
    },
    {
      field: 'formatted_average_filling_time',
      headerName: t('Average Filling Time'),
      width: 220
    },
    {
      field: 'average_filling_percent',
      headerName: `${t('Average Tank')}` + ' %',
      width: 150,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const num1 = parseFloat(v1);
        const num2 = parseFloat(v2);
        return num1 - num2;
      }
    },
    {
      field: 'filling_time_total_transactions',
      headerName: 'Clock',
      width: 70,
      renderHeader: () => <HeaderTooltip
        title="Clock"
        field="filling_time_alert"
      />
    },
    {
      field: 'filling_percent_total_transactions',
      headerName: 'Percent',
      width: 70,
      renderHeader: () => <HeaderTooltip
        title="Percent"
        field="filling_percent_alert"
      />
    }
  ];
  const handleSearchQueryChange = (query: string): void => {
    window.clearTimeout(typingTimeout);

    // Waits until user is finished typing
    const newTimer: number = window.setTimeout((): void => {
      setSearchQuery(query.trim());
    }, 1500);
    setTypingTimeout(newTimer);
  };
  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  });
  const handleDatesSubmit = async (values: any): Promise<any> => {
    if (values.to && values.from) {
      setLoading(true);
      // Call the handleDateChange function when the dates are submitted
      const fromDate = new Date(values.from.year, values.from.month - 1, values.from.day);
      const toDate = new Date(values.to.year, values.to.month - 1, values.to.day, 23, 59, 59);

      try {
        const params = {
          user_id: appState.userId.value,
          account_id: appState.accountId.value,
          start_date: fromDate.getTime() / 1000,
          end_date: toDate.getTime() / 1000
        };
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get-filling-info`, params);
        setData(response.data.names);
      } catch (error) {
        console.log('Error getting filling info data: ', error);
      }
      setLoading(false);
    } else {
      openToast({ type: 'error', label: t('Invalid date selection'), autoClose: 2000, theme: 'dark' });
    }
  };
  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center items-center loading bg-opacity-50 bg-black"
        >
          <CircularProgress />
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="users-line" />
            <span>{t('Fueling Efficiency')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            <SearchButton
              onChange={handleSearchQueryChange}
            />
            <FuelingEfficiencySettingsModal
              fetchData={() => {
                void fetchData();
              }}
              userSettings={userSettings}
            />
            <CalendarPickerModal
              defaultDayRange={defaultPeriod}
              handleDatesSubmit={handleDatesSubmit}
            />
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DataGrid
              onStateChange={(state: GridInitialState): void => {
                handleDataGridStateChange('fuelingEfficiency', state);
              }}
              initialState={appState.dataGridColumnState.value.fuelingEfficiency ?? {}}
              disableDensitySelector={true}
              localeText={{
                noRowsLabel: t('No rows'),
                toolbarColumns: t('Columns'),
                toolbarColumnsLabel: t('Columns'),
                toolbarDensity: t('Density'),
                toolbarDensityLabel: t('Density'),
                toolbarDensityCompact: t('Compact'),
                toolbarDensityStandard: t('Standard'),
                toolbarDensityComfortable: t('Comfortable'),
                // toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                MuiTablePagination: {
                  labelRowsPerPage: t('Rows per Page'),
                  labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                }
              }}
              rows={filteredData}
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              density="compact"
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  // showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true }
                }
              }}
              className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
              sx={{
                height: '50rem',
                '& .MuiDataGrid-row': {
                  borderRadius: '80px',
                  backgroundColor: '#242838',
                  marginTop: '4px',
                  borderBottom: 'none'
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                '& .MuiDataGrid-cell': {
                  border: 'none'
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderRadius: '80px',
                  backgroundColor: '#242838',
                  borderBottom: 'none',
                  marginBottom: '10px'
                },
                border: 0
              }}
            />
          </ThemeProvider>
        </div>
        {isDetailsModalOpen && <FillingInfoDetailsModal
          onHide={() => {
            setIsDetailsModalOpen(false);
          }}
          show={isDetailsModalOpen}
          transactionIds={transactionIds}
        />}
      </div>
    </>
  );
};

export default withTranslation()(FillingInfo);
