import React from 'react';
import TransactionDetailsModal from './Modals/transaction-details/TransactionDetails.modal';
import TransactionGroupDetailsModal from './Modals/transaction-details/TransactionGroupsDetails.modal';
import { t } from 'i18next';
import { renderIconCell } from '../../helpers/columns';
import { type GridColDef } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';

type RemovedDismissedTransactionFunction = (arg: any) => void;

export const columns = (
  headerField: string,
  removedDismissedTransaction: RemovedDismissedTransactionFunction,
  loading: boolean
): GridColDef[] => [
  {
    field: 'icon',
    headerName: t('Icon'),
    width: 100,
    renderCell: renderIconCell
  },
  {
    field: 'details',
    renderHeader: (params) => {
      return (
        <Tooltip title="Unique ID field. This field is what the fuel card used in this transaction is paired with">
          <div className="font-[500]">
            {params.colDef.headerName}
          </div>
      </Tooltip>
      );
    },
    headerName: t(headerField),
    width: 170,
    renderCell: (params: any) => {
      const { kind } = params.row;
      if (kind === 'transactionGroup') {
        return (
          <TransactionGroupDetailsModal
              transactionGroup={params.row}
              removedDismissedTransaction={removedDismissedTransaction}
          />
        );
      }
      return (
        <TransactionDetailsModal
            transaction={params.row}
            isLoading={loading}
            removedDismissedTransaction={removedDismissedTransaction}
        />
      );
    },
    valueGetter: (params: any) => {
      if (params) {
        return params.row.uniqueId;
      } else {
        return '';
      }
    }
  },
  { field: 'vehicleName', headerName: t('Vehicle Name'), width: 250, sortable: !loading },
  { field: 'driverName', headerName: t('Driver Name'), width: 200, sortable: !loading },
  {
    field: 'totalCost',
    headerName: t('Total Cost'),
    width: 150,
    sortable: !loading,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      v1 = v1.replace('$', '');
      v2 = v2.replace('$', '');
      if (!isNaN(v1) && !isNaN(v2)) {
        const num1 = parseFloat(v1);
        const num2 = parseFloat(v2);
        return num1 - num2;
      }
      return 0;
    }
  },
  {
    field: 'numUnits',
    headerName: t('Number of Units'),
    width: 170,
    sortable: !loading,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      if (!isNaN(v1) && !isNaN(v2)) {
        const num1 = parseFloat(v1);
        const num2 = parseFloat(v2);
        return num1 - num2;
      }
      return 0;
    }
  },
  {
    field: 'date',
    headerName: t('Date'),
    width: 150,
    sortable: !loading,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      const date1 = new Date(cellParams1.value);
      const date2 = new Date(cellParams2.value);
      return date1.getTime() - date2.getTime();
    }
  }
];
