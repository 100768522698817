import React from 'react';
import classNames from 'classnames';

type Variant = 'link' | 'light' | 'primary' | 'danger';
type Type = 'submit' | 'button' | 'reset' | undefined;

interface Props {
  name?: string;
  children: React.ReactNode;
  outline?: boolean;
  variant?: Variant;
  disabled?: boolean;
  className?: string;
  type?: Type;
  style?: object;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({
  children,
  outline = false,
  variant = 'primary',
  disabled = false,
  className = '',
  type = 'submit',
  name,
  style,
  onClick
}) => {
  let styles = '';

  if (outline) {
    if (variant === 'light') {
      styles = 'text-custom-gray-normal border border-custom-gray-normal hover:bg-custom-gray-normal hover:text-dark-1 disabled:text-custom-gray-normal disabled:bg-transparent';
    }

    if (variant === 'danger') {
      styles = 'text-error-1 border border-error-1 hover:bg-error-1 hover:text-white';
    }

    if (variant === 'primary') {
      styles = 'text-custom-blue-normal border border-custom-blue-normal hover:bg-custom-blue-normal hover:text-white';
    }
  } else {
    if (variant === 'link') {
      styles = 'text-white/70 hover:text-white';
    }

    if (variant === 'primary') {
      styles = 'bg-custom-blue-normal hover:bg-custom-blue-hover text-white';
    }
  }

  return (
    <button
      type={type}
      className={classNames('transition text-sm h-[28px] px-8 rounded-full mt-6', styles, className)}
      disabled={disabled}
      onClick={onClick}
      name={name}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
