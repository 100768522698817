import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Modal from '../../Modal';
import Icon from '../../Icon';
import IconButton from '../../IconButton';
import Table from '../../Table';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Switch from '../../Switch';
import axios from 'axios';
import { openToast } from '../../../../helpers/toast';

interface Props {
  accounts: any[];
  setAccounts: any;
}

const ProcessErrorsModal: React.FC<Props> = ({ accounts, setAccounts }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const onHide = (): void => {
    setIsShown(false);
  };
  const headers = [
    {
      name: t('Name'),
      width: 150,
      key: 'name'
    },
    {
      name: t('Fuel Card Errors'),
      width: 180,
      key: 'fuelCardErrors'
    },
    {
      name: t('Fuel Transaction Errors'),
      width: 180,
      key: 'fuelTransactionErrors'
    }
  ];
  const toggleFuelCardErrors = async (newValue: boolean, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings/${id}`;
    const params = {
      email_process_file_errors: {
        fuel_card: newValue
      }
    };
    try {
      const response = await axios.put(url, params);
    } catch (err) {
      openToast({ type: 'error', label: 'Error updating settings', autoClose: 2000, theme: 'dark' });
      console.log(err);
    }
  };

  const toggleFuelTransactionErrors = async (newValue: boolean, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings/${id}`;
    const params = {
      email_process_file_errors: {
        fuel_transaction: newValue
      }
    };
    try {
      const response = await axios.put(url, params);
    } catch (err) {
      openToast({ type: 'error', label: 'Error updating settings', autoClose: 2000, theme: 'dark' });
      console.log(err);
    }
  };
  return (
    <>
      <IconButton
        icon="info-circle"
        onClick={() => {
          setIsShown(true);
        }}
      />
        {isShown && <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="info-circle" />
            <p>{t('Process Errors')}</p>
          </div>
        )}
        show={isShown}
        onHide={onHide}
        maxWidth={650}
        >
        <Formik
          enableReinitialize
          initialValues={{
            ...accounts
          }}
          // validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
              onHide();
            }, 400);
          }}
        >
          {(props: any) => {
            return (
              <Form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <Table
                  headers={headers}
                  data={accounts}
                  renderBodyItem={(dataItem: any, index: number) => {
                    return (
                      <div
                        className="flex items-center text-sm bg-dark-2 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                        key={dataItem?.id}
                      >
                        <div
                          style={{
                            width: headers[0].width,
                            maxWidth: headers[0].width,
                            minWidth: headers[0].width
                          }}
                        >
                          {dataItem.name}
                        </div>
                        <div
                          style={{
                            width: headers[1].width,
                            maxWidth: headers[1].width,
                            minWidth: headers[1].width
                          }}
                        >
                          <Switch
                            key={'fuelCardErrors' + `${index}`}
                            checked={dataItem.fuelCardErrors}
                            onClick={(newValue: boolean) => {
                              toggleFuelCardErrors(newValue, dataItem.id);
                              setAccounts(accounts.map((item: any) => {
                                if (item.id === dataItem.id) {
                                  return {
                                    ...item,
                                    fuelCardErrors: newValue
                                  };
                                }
                                return item;
                              }));
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: headers[2].width,
                            maxWidth: headers[2].width,
                            minWidth: headers[2].width
                          }}
                        >
                          <Switch
                              key={'fuelTransactionErrors' + `${index}`}
                              checked={dataItem.fuelTransactionErrors}
                              onClick={(newValue: boolean) => {
                                toggleFuelTransactionErrors(newValue, dataItem.id);
                                setAccounts(accounts.map((item: any) => {
                                  if (item.id === dataItem.id) {
                                    return {
                                      ...item,
                                      fuelTransactionErrors: newValue
                                    };
                                  }
                                  return item;
                                }));
                              }}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>}
    </>
  );
};

export default withTranslation()(ProcessErrorsModal);
