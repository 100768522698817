export enum UNAUTHORIZED_ROUTES {
  HOME = '/',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  UNAUTHORIZED_ACCESS = '/unauthorized',
  USER_ACCESS = '/user-access',
  STATUS = '/status',
}

export enum AUTHORIZED_ROUTES {
  TRANSACTIONS = '/transactions',
  MOST_EXCEPTIONS = '/most-exceptions',
  FILLING_INFO = '/filling-info',
  FUEL_CARDS = '/fuel-cards',
  DISMISSED_TRANSACTIONS = '/dismissed-transactions',
  REPORTS = '/reports',
  RESELLERS = '/resellers',
  USERS = '/users',
  SUPERADMIN = '/superadmin',
  SYSTEM_ADMIN = '/system-admin',
}
