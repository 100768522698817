import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../ui/shared/Sidebar/Sidebar';
import ChildBanner from '../ui/shared/Modals/ChildBanner';
import { appState, useSignal } from '..';

const AuthorizedLayout: React.FC = () => {
  const isLoggedInAsChild = useSignal(appState.loggedInAsChild);
  const isAuthenticated = useSignal(appState.isAuthenticated);

  return (
    <div className="flex items-start bg-dark-1">
      {isLoggedInAsChild && <ChildBanner />}
      <div style={{ marginTop: isLoggedInAsChild ? '50px' : 0 }}>
        { isAuthenticated && <Sidebar /> }
      </div>
      <div
        className="p-4"
        style={{
          width: 'calc(100% - 200px)',
          marginTop: isLoggedInAsChild ? '50px' : 0 // Add top margin only when banner is visible
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default AuthorizedLayout;
