import React, { useState, useEffect } from 'react';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar as CalendarPicker, type DayRange, type Locale } from '@hassanmojab/react-modern-calendar-datepicker';
import './Calendar.scss';
import Button from '../Button';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

interface Props {
  handleDatesSubmit: (selectedDateRange: any) => any;
  defaultDayRange?: DayRange | null;
  hideSubmitButton?: boolean;
  small?: boolean;
}

// Calculate the default "from" date as today
const today = new Date();
const defaultTo = {
  year: today.getFullYear(),
  month: today.getMonth() + 1, // Months are 0-based, so add 1
  day: today.getDate()
};

// Calculate the default "to" date as 7 days ago
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
const defaultFrom = {
  year: sevenDaysAgo.getFullYear(),
  month: sevenDaysAgo.getMonth() + 1,
  day: sevenDaysAgo.getDate()
};

const defaultValue: DayRange = {
  from: defaultFrom,
  to: defaultTo
};

const Calendar: React.FC<Props> = ({ defaultDayRange, handleDatesSubmit, small, hideSubmitButton }) => {
  // Only translating months for now
  const myCustomLocale: Locale = {
    months: [
      t('January'),
      t('February'),
      t('March'),
      t('April'),
      t('May'),
      t('June'),
      t('July'),
      t('August'),
      t('September'),
      t('October'),
      t('November'),
      t('December')
    ],
    weekDays: [
      {
        name: 'Sunday',
        short: 'S',
        isWeekend: true
      },
      {
        name: 'Monday',
        short: 'M'
      },
      {
        name: 'Tuesday',
        short: 'T'
      },
      {
        name: 'Wednesday',
        short: 'W'
      },
      {
        name: 'Thursday',
        short: 'T'
      },
      {
        name: 'Friday',
        short: 'F'
      },
      {
        name: 'Saturday',
        short: 'S',
        isWeekend: true
      }
    ],

    weekStartingIndex: 0,

    getToday (gregorainTodayObject) {
      return gregorainTodayObject;
    },

    toNativeDate (date) {
      return new Date(date.year, date.month - 1, date.day);
    },

    getMonthLength (date) {
      return new Date(date.year, date.month, 0).getDate();
    },

    transformDigit (digit) {
      return digit;
    },

    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    defaultPlaceholder: 'Select...',

    from: 'from',
    to: 'to',

    digitSeparator: ',',

    yearLetterSkip: 0,

    isRtl: false
  };

  const [selectedDateRange, setSelectedDayRange] = useState(defaultDayRange ?? defaultValue);
  useEffect(() => {
    if (defaultDayRange) {
      setSelectedDayRange(defaultDayRange);
    }
  }, [defaultDayRange]);

  useEffect(() => {
    if (hideSubmitButton && selectedDateRange) {
      handleDatesSubmit?.(selectedDateRange);
    }
  }, [hideSubmitButton, selectedDateRange]);

  return (
    <div>
      <CalendarPicker
        value={selectedDateRange} // Use DayRange type
        onChange={setSelectedDayRange}
        colorPrimary="#0fbcf9"
        colorPrimaryLight="#0fbcf9"
        shouldHighlightWeekends
        locale={myCustomLocale}
        calendarClassName={small ? 'small-calendar' : ''}
      />
      {hideSubmitButton
        ? null
        : (
          <div className="calendar-button px-4">
            <Button
              type="button"
              style={{ marginBottom: '20px' }}
              onClick={() => handleDatesSubmit(selectedDateRange)}
            >
              {t('Submit')}
            </Button>
          </div>
          )
        }
    </div>
  );
};

export default withTranslation()(Calendar);
