import React, { useState } from 'react';
import Icon from '../../Icon';
import { t } from 'i18next';

const ShareToolTip: React.FC = () => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="w-min"
      onMouseEnter={() => { setShow(true); }}
      onMouseLeave={() => { setShow(false); }}
    >
      <Icon
        name="circle-info"
        className="cursor-pointer"
      />

      {show && (
        <div className="absolute flex flex-col gap-y-1 z-[9999] bg-dark-3 rounded-lg p-4 shadow-md translate-x-5 translate-y-[-19%] text-sm max-w-[310px]">
          <p>{t('To add CC recipients, please seperate addresses with commas')}</p>
          <div className="h-px min-w-[1px] bg-dark-2 w-11/12"></div>
          <p>{t('Emails should be in the following format: ')}<span className="font-bold">example@mail.com</span></p>
          <p>{t('OR')}</p>
          <p className="font-bold">example1@mail.com, example2@mail.com</p>
        </div>
      )}

    </div>
  );
};

export default ShareToolTip;
