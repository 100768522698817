import React, { useRef, useState, useContext, useEffect } from 'react';
import Icon from '../../../ui/shared/Icon';
import TransactionsAlertModal from '../../../ui/shared/Modals/TransactionsAlert.modal';
import FillingInfoModal from '../../../ui/shared/Modals/filling-info/FillingInfo.modal';
import CalendarPickerModal from '../../../ui/shared/Modals/CalendarPicker.modal';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, type GridInitialState, GridToolbar } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { appState, handleDataGridStateChange } from '../../../index';
import { columns } from '../../../ui/shared/TransactionColumns';
import { AUTHORIZED_ROUTES } from '../../../enums/ROUTES';
import useTransactions from '../../../hooks/useTransactions';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { TransactionsContext } from '../../../contexts/transactionsContext';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const Transactions: React.FC = () => {
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [isAlertSettingsModalOpen, setIsAlertSettingsModalOpen] = useState(false);
  const [isFillingSettingsModalOpen, setIsFillingSettingsModalOpen] = useState(false);
  appState.currentRoute.value = AUTHORIZED_ROUTES.TRANSACTIONS;
  const apiUrl = 'get-dashboard-transaction-discrepancies';
  const {
    loading,
    transformedData,
    rowCountState,
    headerField,
    paginationModel,
    setPaginationModel,
    reloadTransactions,
    removeDismissedTransaction,
    handleDatesSubmit,
    fetchTransactions
  } = useTransactions({ page: 0, pageSize: 50 }, apiUrl);
  const [transactionColumns, setTransactionColumns] = useState(columns(headerField, removeDismissedTransaction, loading));
  const { transactions, setTransactions } = useContext(TransactionsContext);

  useEffect(() => {
    setTransactions(transformedData);
    setTransactionColumns(columns(headerField, removeDismissedTransaction, loading));
  }, [transformedData]);

  // For Debugging State
  // useEffect(() => {
  //   console.log('Transactions context update: ', transactions);
  // }, [transactions]);

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center items-center loading bg-opacity-50 bg-black"
        >
          <CircularProgress/>
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl overflow-hidden">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="arrow-right-arrow-left"/>
            <span>{t('Transactions')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            <FillingInfoModal
              openModal={() => {
                setIsFillingSettingsModalOpen(true);
              }}
              show={isFillingSettingsModalOpen}
              onHide={() => {
                setIsFillingSettingsModalOpen(false);
              }}
              fetchTransactions={fetchTransactions}
            />
            <CalendarPickerModal
              handleDatesSubmit={handleDatesSubmit}
            />
            <TransactionsAlertModal
              openModal={() => {
                setIsAlertSettingsModalOpen(true);
              }}
              show={isAlertSettingsModalOpen}
              onHide={() => {
                setIsAlertSettingsModalOpen(false);
              }}
              fetchTransactions={fetchTransactions}
            />
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div ref={gridRef}>
              <DataGrid
                onStateChange={(state: GridInitialState): void => {
                  handleDataGridStateChange('transactions', state);
                }}
                initialState={appState.dataGridColumnState.value.transactions ?? {}}
                disableDensitySelector={true}
                localeText={{
                  noRowsLabel: t('No rows'),
                  toolbarColumns: t('Columns'),
                  toolbarColumnsLabel: t('Columns'),
                  toolbarDensity: t('Density'),
                  toolbarDensityLabel: t('Density'),
                  toolbarDensityCompact: t('Compact'),
                  toolbarDensityStandard: t('Standard'),
                  toolbarDensityComfortable: t('Comfortable'),
                  toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                  MuiTablePagination: {
                    labelRowsPerPage: t('Rows per Page'),
                    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                  }
                }}
                pagination
                rowCount={rowCountState}
                rows={transactions}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                columns={transactionColumns}
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                density="compact"
                slotProps={{
                  toolbar: { // Disable Export Option
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  },
                  columnsPanel: {
                    disableHideAllButton: true
                  }
                }}
                slots={{ toolbar: GridToolbar }}
                className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                sx={{
                  height: '50rem',
                  '& .MuiDataGrid-row': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    marginTop: '4px',
                    borderBottom: 'none'
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell': {
                    border: 'none'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    borderBottom: 'none',
                    marginBottom: '10px'
                  },
                  border: 0
                }}
              />
            </div>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Transactions);
