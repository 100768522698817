import React, { useState, useEffect } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { CircularProgress, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import '../../../../index.css';
import TransactionDetailsModal from '../transaction-details/TransactionDetails.modal';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { appState } from '../../../../index';
import { openToast } from '../../../../helpers/toast';
import TransactionDetailsShareModal from './TransactionDetailsShare.modal';
import { AUTHORIZED_ROUTES } from '../../../../enums/ROUTES';
import DismissTransaction from './DismissTransaction.modal';
import { t } from 'i18next';
import {
  getTransactionGroupVehicleName,
  transformTransaction
} from '../../../../hooks/useTransactions';
import { isValidCell, renderIconCell } from '../../../../helpers/columns';
import {
  type TransformedTransaction,
  type TransactionDiscrepancyResource,
  type NestedTransactionDiscrepancyResource, type TransformedTransactionGroup
} from '../../../../types/transactions.d';

// the transactionGroup is from the transactions context state
export const getTransformedNestedTransactions = (transactionGroup: TransformedTransactionGroup): TransformedTransaction[] => {
  if (!transactionGroup.transactions) {
    return [];
  }
  // Convert Nested Transaction Resource to Transaction Resource (only difference is TransactionResource has transactionGroup while Nested just has group id)
  return transactionGroup.transactions.map((transaction: NestedTransactionDiscrepancyResource) => {
    // Extract transactionGroupId property from the nested transaction to convert to TransactionDiscrepancyResource
    const { transactionGroupId, ...rest } = transaction;
    return {
      ...transformTransaction({
        ...rest,
        transactionGroup: null
      } satisfies TransactionDiscrepancyResource),
      transactionGroupId
    };
  });
};

interface Props {
  transactionGroup: TransformedTransactionGroup;
  removedDismissedTransaction?: any;
}

const TransactionGroupDetailsModal: React.FC<Props> = ({
  transactionGroup,
  removedDismissedTransaction
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountSettings, setAccountSettings] = useState<any>(null);
  const [vehicleNames, setVehicleNames] = useState<string[]>([]);
  const fetchAccountSettings = async (): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings?filter=account_id:${appState.accountId.value}(eq)&include=account`;
    try {
      const accSettingsRes = await axios.get(url);
      setAccountSettings(accSettingsRes.data.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    void fetchAccountSettings();
  }, []);

  useEffect(() => {
    setVehicleNames(getTransactionGroupVehicleName(transactionGroup.transactions));
  }, [transactionGroup]);

  const columns = [
    {
      field: 'icon',
      headerName: t('Icon'),
      width: 50,
      renderCell: renderIconCell
    },
    {
      field: 'details',
      headerName: `${t('Details')}`,
      width: 150,
      renderCell: (params: any) => {
        return (
          <TransactionDetailsModal
            transaction={params.row}
            removedDismissedTransaction={removedDismissedTransaction}
            group={true}
          />
        );
      }
    },
    { field: 'totalCost', headerName: t('Total Cost'), width: 100 },
    { field: 'numUnits', headerName: t('Number of Units'), width: 150 },
    { field: 'date', headerName: t('Date'), width: 100 }
  ];
  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

  const markSingleTransaction = async (transaction: any): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/fuel-transactions/` + String(transaction.id);
    const params = {
      _method: 'PUT',
      marked_good: appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 0 : 1,
      marked_sus: 0
    };
    try {
      await axios.post(url, params);
    } catch (err) {
      console.log(err);
      openToast({
        type: 'error',
        label: appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 'Error restoring Transaction ' : 'Error dismissing Transaction',
        autoClose: 2000,
        theme: 'dark'
      });
      openToast({ type: 'error', label: 'Error dismissing transaction', autoClose: 2000, theme: 'dark' });
    }
  };

  const markGroupedTransactions = (group: TransformedTransactionGroup): void => {
    (async () => {
      const promises: any[] = [];
      const transactions: any[] = [];
      group?.transactions?.forEach((transaction: any) => {
        promises.push(markSingleTransaction(transaction));
        transactions.push(transaction);
      });
      try {
        Promise.all(promises).then(() => {
          removedDismissedTransaction(group.id, transactions);
        });
        if (appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS && group.annotationId) {
          const url = `${process.env.REACT_APP_API_URL}/api/approval-annotation/${group.annotationId}`;
          await axios.delete(url);
        }
        openToast({
          type: 'success',
          label: appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 'Transactions restored' : 'Transactions dismissed',
          autoClose: 2000,
          theme: 'dark'
        });
        setIsModalOpen(false);
      } catch (err) {
        console.log(err);
        openToast({
          type: 'error',
          label: appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 'Error restoring transactions' : 'Error dismissing transactions',
          autoClose: 2000,
          theme: 'dark'
        });
      }
    })();
  };

  return (
    <>
      <button
        type="button"
        className="text-custom-blue-normal hover:text-custom-blue-hover"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {!isValidCell(transactionGroup.uniqueId) ? 'N/A' : transactionGroup.uniqueId}
      </button>
      {isModalOpen &&
        <Modal
          header={(
            <div className="flex items-center gap-x-2">
              <Icon name="layer-group"/>
              <p>{t('Group Transaction Details')}</p>
            </div>
          )}
          maxWidth="75rem"
          minHeight="30rem"
          show={isModalOpen}
          onHide={() => {
            setIsModalOpen(false);
          }}
        >
          {isSubmitting && (
            <div className="loading-modal-overlay">
              <CircularProgress/>
            </div>
          )}
          <div className="flex gap-x-10 mt-3 text-sm">
            <ThemeProvider theme={theme}>
              <CssBaseline/>
              <div>
                <DataGrid
                  localeText={{
                    noRowsLabel: t('No rows'),
                    toolbarColumns: t('Columns'),
                    toolbarColumnsLabel: t('Columns'),
                    toolbarDensity: t('Density'),
                    toolbarDensityLabel: t('Density'),
                    toolbarDensityCompact: t('Compact'),
                    toolbarDensityStandard: t('Standard'),
                    toolbarDensityComfortable: t('Comfortable'),
                    toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                    MuiTablePagination: {
                      labelRowsPerPage: t('Rows per Page'),
                      labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                    }
                  }}
                  rows={getTransformedNestedTransactions(transactionGroup)}
                  columns={columns}
                  hideFooterPagination
                  disableColumnFilter
                  disableColumnMenu
                  disableRowSelectionOnClick
                  density="compact"
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: false,
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true }
                    }
                  }}
                  className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                  sx={{
                    height: '100%',
                    minHeight: '500px',
                    width: '100%',
                    minWidth: '650px',
                    '& .MuiDataGrid-row': {
                      borderRadius: '80px',
                      backgroundColor: '#242838',
                      marginTop: '4px',
                      borderBottom: 'none'
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-cell': {
                      border: 'none'
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderRadius: '80px',
                      backgroundColor: '#242838',
                      borderBottom: 'none',
                      marginBottom: '10px'
                    },
                    border: 0
                  }}
                />
              </div>
            </ThemeProvider>
            <div className="bg-dark-1 w-px"/>
            <div className="flex-grow flex flex-col gap-y-8">
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <p className="font-light">{t('Driver')}</p>
                  <p className="font-bold">{!isValidCell(transactionGroup.transactions?.[0].driverFname) ? 'N/A' : transactionGroup.transactions?.[0].driverFname}</p>
                </div>
                <div>
                  <p className="font-light">{t(vehicleNames.length === 1 ? 'Vehicle Name' : 'Vehicle Names')}</p>
                  {vehicleNames.length === 1
                    ? <p
                      className="font-bold"
                    >
                      {vehicleNames[0]}
                    </p>
                    : <div>
                      {vehicleNames.map((vehicleName: string) => (
                        <p
                          key={vehicleName}
                          className="font-bold"
                        >
                          {vehicleName}
                        </p>
                      ))}
                    </div>
                  }
                </div>
                <div>
                  <p className="font-light">{t('Vehicle Group')}</p>
                  <p className="font-bold">{t('Multiple')}</p>
                </div>
                <div>
                  <p className="font-light">{t('Date')}</p>
                  <p className="font-bold">{transactionGroup.date}</p>
                </div>
                <div>
                  <p className="font-light">{t('Total Units Purchased')}</p>
                  <p className="font-bold">{transactionGroup.numUnits}</p>
                </div>
                <div>
                  <p className="font-light">{t('Total Cost')}</p>
                  <p className="font-bold">{transactionGroup.totalCost}</p>
                </div>
                <div>
                  <p className="font-light">{t('Total Fuel Put In')}</p>
                  <p
                    className="font-bold"
                  >{transactionGroup.amountPutIn == null ? 'N/A' : transactionGroup.amountPutIn}</p>
                </div>
                <div>
                  <p className="font-light">{t('Fuel Difference')}</p>
                  <p className="font-bold">{transactionGroup.fuelLevelDifference}</p>
                </div>
                <div>
                  <p className="font-light">{t('Total Transactions')}</p>
                  <p className="font-bold">{(transactionGroup?.transactions)?.length}</p>
                </div>
              </div>
              <div className="h-px min-w-[1px] bg-dark-2 w-11/12"></div>
              <div>
                <DismissTransaction
                  transactionData={transactionGroup}
                  removedDismissedTransaction={removedDismissedTransaction}
                  accountSettings={accountSettings}
                  markGroupedTransactions={markGroupedTransactions}
                />
              </div>
              <div>
                <TransactionDetailsShareModal
                  callback={() => {
                    setIsModalOpen(false);
                  }}
                  group={true}
                  // Really only needs id, and vehicle name
                  transactionGroup={transactionGroup}
                />
              </div>
            </div>
          </div>
        </Modal>
      }
    </>

  );
};

export default TransactionGroupDetailsModal;
