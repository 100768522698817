import { appState } from '../index';
import axios from 'axios';
import { effect } from '@preact/signals';

const useUpdateAxiosAuthHeader = (): void => {
  effect(() => {
    const token = appState.loggedInAsChild.value ? appState.childAuthToken.value : appState.authToken.value;
    if (token) {
      axios.defaults.headers.common.Authorization = token;
    }
  });
};

export default useUpdateAxiosAuthHeader;
