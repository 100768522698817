import React, { useState, useEffect } from 'react';
import Icon from '../../Icon';
import axios from 'axios';
import { openToast } from '../../../../helpers/toast';
import { t } from 'i18next';
import { type TransformedTransaction } from '../../../../types/transactions.d';

interface Props {
  transactionData: TransformedTransaction;
  vehicleLocation?: any;
}

const DateTimeToolTip: React.FC<Props> = ({ transactionData, vehicleLocation }) => {
  const [show, setShow] = useState(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [originalDate, setOriginalDate] = useState<string>('');
  const [changedDate, setChangedDate] = useState<string>('');

  const getGoogleTimezone = async (): Promise<any> => {
    const url = `${process.env.REACT_APP_API_URL}/api/make-google-maps-request`;
    const params = {
      location: String(vehicleLocation.latitude) + ',' + String(vehicleLocation.longitude),
      timestamp: transactionData.timestamp,
      url: 'timezone/json'
    };
    try {
      const response = await axios.post(url, { params });
      return response.data.timeZoneId;
    } catch (err) {
      console.log(err);
      openToast({ type: 'error', label: 'Error getting timezone', autoClose: 2000, theme: 'dark' });
    }
  };

  const convertTimeZone = (date: Date, timezone: string): Date => {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: timezone }));
  };

  const convertDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (String((date.getMonth() + 1)).slice(-2).length === 1
      ? '0' + String((date.getMonth() + 1)).slice(-2)
      : String((date.getMonth() + 1)).slice(-2));

    const day = (String(date.getDate()).slice(-2).length === 1
      ? '0' + String(date.getDate()).slice(-2)
      : String(date.getDate()).slice(-2));

    let hour = date.getHours();
    const minute = (String(date.getMinutes()).slice(-2).length === 1
      ? '0' + String(date.getMinutes()).slice(-2)
      : String(date.getMinutes()).slice(-2));

    let ampm;
    if (hour < 12) {
      if (hour === 0) {
        hour = 12;
      }
      ampm = 'AM';
    } else if (hour >= 12) {
      if (hour !== 12) {
        hour -= 12;
      }
      ampm = 'PM';
    } else {
      ampm = 'PM';
    }
    return `${hour}:${minute} ${ampm} ${month}/${day}/${year}`;
  };

  useEffect(() => {
    if (transactionData.timestamp !== transactionData.originalTimestamp) {
      (async () => {
        const timezone = await getGoogleTimezone();
        const original = new Date(transactionData.originalTimestamp * 1000);
        const changed = new Date(transactionData.timestamp * 1000);
        setOriginalDate(convertDate(convertTimeZone(original, timezone)));
        setChangedDate(convertDate(convertTimeZone(changed, timezone)));
        setChanged(true);
      })();
    } else {
      setChanged(false);
    }
  }, []);

  return (
    <>
      <div
        className="w-min"
        onMouseEnter={() => { setShow(true); }}
        onMouseLeave={() => { setShow(false); }}
      >
        <Icon name="circle-info" />

        {show && changed
          ? (
          <div className="absolute flex flex-col gap-y-3 z-[9999] bg-dark-3 rounded-lg p-4 shadow-md translate-x-5 translate-y-[-25%]">
            <h3 className="text-lg">{t('Details changed')}</h3>
            <div>
              <p className="font-bold">{t('Original timestamp')}</p>
              <p>{String(originalDate)}</p>
            </div>
            <div>
              <p className="font-bold">{t('Changed timestamp')}</p>
              <p>{String(changedDate)}</p>
            </div>
          </div>
            )
          : show
            ? <div className="absolute flex flex-col gap-y-3 z-[9999] bg-dark-3 rounded-lg p-4 shadow-md translate-x-5 translate-y-[-25%]">
                <h3>{t('No details changed')}</h3>
            </div>
            : <></>}
      </div>
    </>
  );
};

export default DateTimeToolTip;
