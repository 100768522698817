import React, { useState, useEffect } from 'react';
import IconButton from '../IconButton';
import Calendar from '../Calendar/Calendar';
import 'rc-dropdown/assets/index.css';
import Dropdown from '../Dropdown';
import { type DayRange } from '@hassanmojab/react-modern-calendar-datepicker';

interface Props {
  handleDatesSubmit?: (selectedDateRange: any) => any;
  defaultDayRange?: DayRange | null;
  loading?: boolean;
  hideSubmitButton?: boolean;
}

const CalendarPickerModal: React.FC<Props> = ({ defaultDayRange, handleDatesSubmit, loading, hideSubmitButton }) => {
  const [isOpen, setIsOpen] = useState(false);

  let toggleOpen = (): void => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (datesSubmitted: any): void => {
    if (handleDatesSubmit) {
      handleDatesSubmit(datesSubmitted);
    }
    toggleOpen();
  };

  useEffect(() => {
  }, [isOpen]);

  return (
    <div>
      <Dropdown
        handle={(toggleOpenFn: () => void) => {
          toggleOpen = toggleOpenFn;
          return (
            <IconButton
              icon="calendar"
              onClick={loading ? () => {} : toggleOpenFn}
            />
          );
        }}
      >
        <Calendar
          defaultDayRange={defaultDayRange}
          handleDatesSubmit={handleSubmit}
          hideSubmitButton={hideSubmitButton}
        />
      </Dropdown>
    </div>
  );
};

export default CalendarPickerModal;
