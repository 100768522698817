import { animated, useTransition } from '@react-spring/web';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  title: string;
  children: React.ReactNode;
  noAnimation?: boolean;
}

const Page: React.FC<Props> = ({
  title,
  children = null,
  noAnimation = false
}) => {
  const location = useLocation();
  const transitions = useTransition(location.pathname, {
    from: { opacity: 0, transform: 'translateY(150px)' },
    enter: { opacity: 1, transform: 'translateY(0)' }
  });

  useEffect(() => {
    document.title = `BlueArrow Fuel | ${title}`;
  }, [title]);

  return transitions((style) => (
    <div className="relative">
      <animated.div style={noAnimation ? {} : style}>{children}</animated.div>
    </div>
  ));
};

export default Page;
