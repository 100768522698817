/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import classNames from 'classnames';
import React from 'react';
import { generateUUID } from '../../helpers/utilities';

interface IHeader {
  name?: string;
  headerComponent?: React.ReactNode;
  width?: string | number;
  key?: string;
}

interface Props<T> {
  headerComponent?: React.ReactNode;
  headers: IHeader[];
  data: T[];
  renderBodyItem?: (item: unknown, index: number) => JSX.Element;
}

const Table: React.FC<Props<unknown>> = ({
  headerComponent,
  headers,
  data,
  renderBodyItem
}) => {
  return (
    <div>
      {headerComponent}
      <div
        style={{ marginTop: '1rem' }}
        className="overflow-auto max-h-50"
      >
        <div
          className={classNames(
            'flex bg-dark-2 text-sm text-custom-gray-normal px-4 py-2 rounded-lg gap-x-6 sticky top-0 z-50'
          )}
        >
          {headers.map((item: IHeader) => {
            if (item?.headerComponent) {
              return item?.headerComponent;
            }

            return (
              <p
                key={`header-${item?.name ?? generateUUID()}`}
                style={{
                  width: item.width,
                  maxWidth: item.width
                }}
              >
                {item.name}
              </p>
            );
          })}
        </div>
        <div className="flex flex-col mt-3 gap-y-2">
          {data.map((dataItem: unknown, index: number) => {
            if (renderBodyItem) {
              return renderBodyItem(dataItem, index);
            }

            return (
              <div
                className="flex bg-dark-2 text-sm text-white px-4 py-2 rounded-lg gap-x-6"
                // @ts-ignore
                key={dataItem?.id}
              >
                {headers.map((item: IHeader) => (
                  <div
                    key={`data-${item.name ?? generateUUID()}`}
                    style={{
                      width: item.width,
                      minWidth: item.width
                    }}
                  >
                    {/* @ts-ignore */}
                    {dataItem?.[item?.key]}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Table;
