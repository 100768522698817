import { type Dispatch, type SetStateAction } from 'react';
import { type User } from '../routes/Authorized/Users/UserTypes';
import { appState } from '..';

export const updateUsers = (updatedUserData: User, method: 'update' | 'remove' | 'create' | 'geotabUser', setUsers: Dispatch<SetStateAction<User[]>>): void => {
  setUsers((prevUsers: User[]): User[] => {
    switch (method) {
      case 'update':
        if (appState.userId.value === `${updatedUserData.id}`) {
          // Will really only apply to super admins since admins can't change their own roles and neither can users
          appState.userRole.value = updatedUserData.role;
        }
        return prevUsers.map((user: User): User => {
          if (user.id === updatedUserData.id) {
            user = { ...updatedUserData };
          }
          return user;
        });
      case 'remove':
        return prevUsers.filter((user: User): boolean => user.id !== updatedUserData.id);
      case 'create':
        return [...prevUsers, updatedUserData];
      case 'geotabUser':
        return prevUsers.map((user: User): User => {
          // Remove previous owner when needed
          if (user.geotabUserId === updatedUserData.geotabUserId) {
            user = {
              ...user,
              geotabUsername: null,
              geotabUserId: null
            };
          }
          if (user.id === updatedUserData.id) {
            user = { ...updatedUserData };
          }
          return user;
        });
      default:
        return prevUsers;
    }
  });
};
