import React, { useState } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Button from '../../Button';
import Input from '../../Formik/Input';
import { Formik } from 'formik';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { openToast } from '../../../../helpers/toast';
import { appState } from '../../../../index';
import { AUTHORIZED_ROUTES } from '../../../../enums/ROUTES';
import { t } from 'i18next';
import { type TransformedTransaction, type TransformedTransactionGroup } from '../../../../types/transactions.d';

interface Props {
  removedDismissedTransaction: (id: number, transaction: any) => void;
  transactionData: TransformedTransaction | TransformedTransactionGroup;
  accountSettings: any;
  markGroupedTransactions?: (group: TransformedTransactionGroup) => void;
}

const mimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];

const DismissTransaction: React.FC<Props> = ({
  transactionData,
  removedDismissedTransaction,
  accountSettings,
  markGroupedTransactions
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any>('');
  const sendDismissalAnnotations = async (): Promise<void> => {
    if (!appState.userId.value) return;
    if (file && !mimeTypes.includes(file.type)) {
      openToast({ type: 'error', label: 'File type must be in PDF, JPG, or PNG format', autoClose: 2000, theme: 'dark' });
    } else {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/api/approval-annotation`;
      const params = new FormData();
      if (comment) {
        params.append('comment', comment);
      }
      if (file) {
        params.append('filename', file.name.split('\\').pop());
        params.append('file', file);
      }
      if (transactionData.kind === 'transactionGroup') {
        params.append('transaction_group_id', `${transactionData.id}`);
        params.append('user_id', accountSettings.account.geotab_user_id);
      } else {
        params.append('fuel_transaction_id', String(transactionData.id));
        // params.append('user_id', transactionData.account.geotab_user_id);
        params.append('user_id', appState.userId.value); // new
      }
      params.append('account_id', accountSettings.account_id);

      try {
        await axios.post(url, params);
        await toggleTransactionDismissal();
      } catch (err) {
        console.log(err);
        openToast({ type: 'error', label: 'Error sending annotations', autoClose: 2000, theme: 'dark' });
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleTransactionDismissal = async (): Promise<void> => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/api/fuel-transactions/`;
      let params;

      if (transactionData.kind === 'transactionGroup' && markGroupedTransactions) {
        markGroupedTransactions(transactionData);
      } else {
        url += String(transactionData?.id);
        params = {
          _method: 'PUT',
          marked_good: appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 0 : 1,
          marked_sus: 0
        };
        await axios.post(url, params);

        if (appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS && transactionData?.annotationId) {
          setLoading(true);
          url = `${process.env.REACT_APP_API_URL}/api/approval-annotation/${transactionData.annotationId}`;
          await axios.delete(url);
        }
        removedDismissedTransaction(transactionData?.id ?? -1, transactionData);
        setIsShown(false);

        openToast({
          type: 'success',
          label: appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 'Transaction restored' : 'Transaction dismissed',
          autoClose: 2000,
          theme: 'dark'
        });
      }
    } catch (error: any) {
      console.error(error);
      openToast({
        type: 'error',
        label: appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 'Error restoring transaction' : 'Error dismissing transaction',
        autoClose: 2000,
        theme: 'dark'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        className="!mt-0 !px-4 flex items-center gap-x-1"
        type="button"
        disabled={loading}
        onClick={() => {
          if (accountSettings.use_annotations && appState.currentRoute.value !== AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS) {
            setIsShown(true);
          } else {
            void toggleTransactionDismissal();
          }
        }
        }
      >
        <Icon name={appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? 'thumbs-down' : 'thumbs-up'}/>
        {appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? `${t('Restore Transaction')}` : `${t('Dismiss Transaction')}`}
      </Button>
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="comment"/>
            <p>{appState.currentRoute.value === AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS ? `${t('Restore Transaction')}` : `${t('Dismiss Transaction')}`}</p>
          </div>
        )}
        show={isShown}
        onHide={() => {
          setIsShown(false);
        }}
        customZIndex={1001}
      >
        <Formik
          enableReinitialize
          initialValues={{ message: '', file: '' }}
          onSubmit={(values, { setSubmitting }) => {
            void sendDismissalAnnotations();
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                {loading && (
                  <div
                    className="w-full h-full absolute inset-0 flex justify-center items-center z-[1005] bg-opacity-20 bg-black"
                  >
                    <CircularProgress/>
                  </div>
                )}
                <div className="flex flex-col gap-y-6">
                  <Input
                    name="message"
                    type="text"
                    label={`${t('Comments *')}`}
                    setState={setComment}
                    stateValue={comment}
                  />
                  {<Input
                    name="upload"
                    type="file"
                    label={`${t('File')}`}
                    setState={setFile}
                  />}
                  {showError && <p className="text-left">{errorMessage} <span className="text-red-500">*</span></p>}
                </div>
                {file && <div className="flex items-center gap-x-2">
                  <Button
                    style={{ marginTop: 5, padding: '0 8px' }}
                    outline
                    onClick={() => {
                      setFile('');
                    }}
                  >
                    <Icon name="trash"/>
                  </Button>
                  <p className="text-xs">{file.name}</p>
                </div>}
                <div className="flex justify-center">
                  <Button
                    disabled={props.isSubmitting}
                    className="!px-12"
                  >
                    {t('Submit')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default DismissTransaction;
