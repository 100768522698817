import React, { useEffect, useState } from 'react';
import IconButton from '../../../ui/shared/IconButton';
import Modal from '../../../ui/shared/Modal';
import Icon from '../../../ui/shared/Icon';
import { Formik } from 'formik';
import Input from '../../../ui/shared/Formik/Input';
import Button from '../../../ui/shared/Button';
import { t } from 'i18next';
import axios, { AxiosError } from 'axios';
import { getParentAuthHeader } from '../../../helpers/api';
import { appState } from '../../..';
import { openToast } from '../../../helpers/toast';
import { CircularProgress } from '@mui/material';
import * as yup from 'yup';
import FuelCardSettingsTooltip from './FuelCardSettingsTooltip';
import {
  type AccountToFcp,
  type FuelCardProvider,
  type DefaultPairing
} from '../../../types/fcp.d';
import Switch from '../../../ui/shared/Switch';

interface FuelCardSettingsProps {
  accountToFcpData: AccountToFcp[];
}

const defaultPairingsSchema = yup.object().shape({
  // fcp_field: yup.string().required('Required'),
  // geotab_field: yup.string().required('Required')
});

const FuelCardSettingsModal: React.FC<FuelCardSettingsProps> = ({ accountToFcpData }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [accountToFcpId, setAccountToFcpId] = useState<number | null>(null);
  const [pairs, setPairs] = useState<DefaultPairing[]>([]);
  const [selectedPair, setSelectedPair] = useState<DefaultPairing | null>(null);
  const [loading, setLoading] = useState(false);
  const [accountToFcp, setAccountToFcp] = useState<AccountToFcp | null>(null);
  const [fcpName, setFcpName] = useState<string | null>(null);
  const [fcpFieldOptions, setFcpFieldOptions] = useState<any[]>([]);
  const [geotabFieldOptions, setGeotabFieldOptions] = useState<any[]>([]);

  // field options
  const vehicleFcpFields = [
    { key: t('VIN'), value: 'vin' },
    { key: t('Card Pin'), value: 'card_pin' },
    { key: t('Name On Card'), value: 'name_on_card' },
    { key: t('Unit Card Id'), value: 'unique_card_id' },
    { key: t('Unit Number'), value: 'unit_number' },
    { key: t('Vehicle Number'), value: 'vehicle_number' }
  ];
  const vehicleGeotabFields = [
    { key: t('VIN'), value: 'vehicleIdentificationNumber' },
    { key: t('Vehicle Name'), value: 'name' },
    { key: t('Comments'), value: 'comment' },
    { key: t('License Plate'), value: 'licensePlate' },
    { key: t('Serial Number'), value: 'serialNumber' }
  ];
  const driverFcpFields = [
    { key: t('Card Pin'), value: 'card_pin' },
    { key: t('Name On Card'), value: 'name_on_card' },
    { key: t('Unit Card Id'), value: 'unique_card_id' },
    { key: t('Driver ID'), value: 'driver_id' },
    { key: t('Unit Number'), value: 'unit_number' }
  ];
  const driverGeotabFields = [
    { key: t('Comments'), value: 'comment' },
    { key: t('Employee Number'), value: 'employeeNo' },
    { key: t('License Number'), value: 'licenseNumber' },
    { key: t('Name'), value: 'name' },
    { key: t('Phone Number'), value: 'phoneNumber' },
    { key: t('Designation'), value: 'designation' }
  ];

  const getPairs = async (): Promise<void> => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards/default-pairing/${appState.accountId.value}`;
    try {
      const response = await axios.get(url);
      console.log(response);
      setPairs(response.data.data || []);

      if (response.data.data.length === 0) {
        return;
      }

      // set the initial pairing
      handleSelectPair(response.data.data[0]);
      const accountToFcp: AccountToFcp | null = findAccountToFcpById(response.data.data[0].account_to_fcp_id);
      if (accountToFcp) {
        setAccountToFcp(accountToFcp);
        setAccountToFcpId(accountToFcp.accountToFcpId);
        setFcpName(findNameByAccountToFcpId(accountToFcp.accountToFcpId) ?? '');
      }
    } catch (err) {
      console.log(err);
      openToast({ type: 'error', label: t('Error getting default pairings'), autoClose: 2000, theme: 'dark' });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPair = (selectedPair: DefaultPairing | null): void => {
    setSelectedPair(selectedPair);
  };

  const handleSubmit = async (settingsSubmitted: any): Promise<boolean> => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards/default-pairing/${appState.accountId}`;
      const params = {
        account_to_fcp_id: settingsSubmitted.account_to_fcp_id,
        fcp_field: settingsSubmitted.fcp_field,
        geotab_field: settingsSubmitted.geotab_field,
        wildcard_search: settingsSubmitted.wildcard_search
      };
      const headers = getParentAuthHeader();
      await axios.put(
        url,
        params,
        headers ? { headers } : undefined
      );
      openToast({ type: 'success', label: t('Default Pairing Updated'), autoClose: 2000, theme: 'dark' });
      return true;
    } catch (error: AxiosError | any) {
      console.error('Error happened updating default pairing: ', error);
      let message = 'Error updating default pairing';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      return false;
    }
  };

  useEffect(() => {
    if (isShown) {
      setSelectedPair(null);
      getPairs();
    }
  }, [isShown]);

  async function removeDefaultPairing (props: any): Promise<void> {
    let id;
    if (!accountToFcp) {
      id = 0;
    } else {
      id = accountToFcp.accountToFcpId;
    }
    const clearedDefaultPairs = {
      fcp_field: '',
      geotab_field: '',
      wildcard_search: false,
      account_to_fcp_id: id
    };
    props.setSubmitting(true);
    let hide = false;
    hide = await handleSubmit(clearedDefaultPairs);
    props.setSubmitting(false);
    if (hide) {
      setIsShown(false);
    }
  };

  const findAccountToFcp = (name: FuelCardProvider | null): AccountToFcp | null => {
    for (const accountToFcp of accountToFcpData) {
      if (accountToFcp.name === name) {
        return accountToFcp;
      }
    }
    return null;
  };

  const findNameByAccountToFcpId = (id: number): string | undefined => {
    return accountToFcpData.find((accountToFcp) => accountToFcp.accountToFcpId === id)?.name;
  };

  const findAccountToFcpById = (id: number): AccountToFcp | null => {
    const accountToFcp = accountToFcpData.find((accountToFcp) => accountToFcp.accountToFcpId === id);
    return accountToFcp ?? null;
  };

  const findPairByAccountToFcpId = (id: number): DefaultPairing | undefined => {
    return pairs.find((pair) => pair.account_to_fcp_id === id);
  };

  const handleProviderChange = async (name: FuelCardProvider, setValues: (values: any) => void): Promise<void> => {
    const accountToFcp: AccountToFcp | null = findAccountToFcp(name);
    const emptyPair: DefaultPairing = {
      fcp_field: '',
      geotab_field: '',
      wildcard_search: 0
    };
    if (accountToFcp) {
      setAccountToFcp(accountToFcp);
      setAccountToFcpId(accountToFcp.accountToFcpId);
      setFcpName(findNameByAccountToFcpId(accountToFcp.accountToFcpId) ?? '');
      const newSelectedPair = findPairByAccountToFcpId(accountToFcp.accountToFcpId);
      if (newSelectedPair) {
        handleSelectPair(newSelectedPair);
        setValues({
          fcp_field: newSelectedPair.fcp_field,
          geotab_field: newSelectedPair.geotab_field,
          wildcard_search: newSelectedPair.wildcard_search ?? false
        });
      } else {
        // Reset form fields if no corresponding pair exists
        handleSelectPair(emptyPair);
        setValues({
          fcp_field: '',
          geotab_field: '',
          wildcard_search: false
        });
      }

      // set fcp field and geotab field options
      if (accountToFcp.preferredMatch === 'vehicle') {
        setFcpFieldOptions(vehicleFcpFields);
        setGeotabFieldOptions(vehicleGeotabFields);
      } else if (accountToFcp.preferredMatch === 'driver') {
        setFcpFieldOptions(driverFcpFields);
        setGeotabFieldOptions(driverGeotabFields);
      }
    } else {
      handleSelectPair(emptyPair);
      setValues({
        fcp_field: '',
        geotab_field: '',
        wildcard_search: false
      });
    }
  };

  return (
    <>
      <IconButton
        icon="gear"
        onClick={() => {
          setIsShown(true);
        }}
      />
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="gear" />
            <p>{t('Fuel Card Settings')}</p>
          </div>
        )}
        show={isShown}
        onHide={() => {
          setIsShown(false);
        }}
        maxWidth={350}
      >
        {loading
          ? (
            <div className="loading-overlay">
              <CircularProgress />
            </div>
            )
          : (
            <Formik
              enableReinitialize
              validationSchema={defaultPairingsSchema}
              initialValues={{
                name: fcpName ?? '',
                fcp_field: selectedPair ? selectedPair.fcp_field : '',
                geotab_field: selectedPair ? selectedPair.geotab_field : '',
                wildcard_search: selectedPair ? !!selectedPair.wildcard_search : false
              }}
              onSubmit={async (values, { setSubmitting }) => {
                if (!accountToFcpId || !selectedPair) {
                  return false;
                }
                setSubmitting(true);
                let hide = false;
                const submittedSettings = {
                  fcp_field: selectedPair.fcp_field,
                  geotab_field: selectedPair.geotab_field,
                  wildcard_search: selectedPair.wildcard_search,
                  account_to_fcp_id: accountToFcpId
                };
                hide = await handleSubmit(submittedSettings);
                setSubmitting(false);
                if (hide) {
                  setIsShown(false);
                }
              }}
            >
              {(props: any) => (
                <form
                  onSubmit={props.handleSubmit}
                  className="mt-4"
                >
                  {/* Display loading spinner when isSubmitting is true */}
                  {props.isSubmitting && (
                    <div className="loading-overlay">
                      <CircularProgress />
                    </div>
                  )}
                  <div className="flex flex-col gap-y-2">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase font-bold">{t('Default Pairing')}</p>
                      <FuelCardSettingsTooltip />
                    </div>
                    <div className="flex flex-col gap-y-4">
                    <Input
                      name="name"
                      type="select"
                      options={accountToFcpData.length ? accountToFcpData.map((fcp: AccountToFcp) => fcp.name) : []}
                      onChange={(option: FuelCardProvider): void => {
                        void handleProviderChange(option, props.setValues);
                      }}
                      label={t('Provider')}
                      defaultValue={fcpName ?? ''}
                    />
                  <Input
                    name="fcp_field"
                    type="select"
                    label={`${t('Select FCP Field')}`}
                    options={fcpFieldOptions}
                    value={selectedPair ? selectedPair.fcp_field : ''}
                    defaultValue={selectedPair ? selectedPair.fcp_field : ''}
                    onChange={(event) => {
                      const newSelectedPair = { ...selectedPair, fcp_field: event };
                      setSelectedPair(newSelectedPair);
                    }}
                    placeholder={t('Select FCP Field')}
                  />
                      <Input
                        name="geotab_field"
                        type="select"
                        label={`${t('Select Geotab Field')}`}
                        options={geotabFieldOptions}
                        value={selectedPair ? selectedPair.geotab_field : ''}
                        defaultValue={selectedPair ? selectedPair.geotab_field : ''}
                        onChange={(event) => {
                          const newSelectedPair = { ...selectedPair, geotab_field: event };
                          setSelectedPair(newSelectedPair);
                        }}
                      />
                      <Switch
                        label={t('Wildcard Search')}
                        checked={selectedPair ? !!selectedPair.wildcard_search : false}
                        onClick={(newValue: boolean) => {
                          const newSelectedPair = { ...selectedPair, wildcard_search: newValue };
                          setSelectedPair(newSelectedPair);
                        }}
                      />
                    </div>
                    {(props.values.fcp_field !== '' || props.values.geotab_field !== '') && (
                      <Button
                        disabled={props.isSubmitting}
                        style={{ margin: '15px 0px -10px' }}
                        type="button"
                        onClick={() => { removeDefaultPairing(props); }}
                      >
                        {t('Remove Default Pairing')}
                      </Button>
                    )}
                    <Button
                      disabled={props.isSubmitting}
                      className="!px-12"
                    >
                      {t('Submit')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
            )}
      </Modal>
    </>
  );
};

export default FuelCardSettingsModal;
