import { useEffect, useState } from 'react';
import { fetchGeotabVehicles } from '../helpers/api';
import { type GeotabVehicle } from '../types/fcp.d';

export const useVehicleData = (): any => {
  const [vehicles, setVehicles] = useState<GeotabVehicle[]>([]);

  useEffect(() => {
    const fetchVehicles = async (): Promise<void> => {
      const fetchedVehicles: GeotabVehicle[] | null = await fetchGeotabVehicles();
      setVehicles(fetchedVehicles ?? []);
    };
    void fetchVehicles();
  }, []);

  return vehicles;
};
