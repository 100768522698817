import React from 'react';
import { Formik, type FormikProps } from 'formik';
import * as Yup from 'yup';
import Input from '../../../ui/shared/Formik/Input';
import Button from '../../../ui/shared/Button';

interface Values {
  email: string;
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
});

const ForgotPassword: React.FC = () => {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {(props: FormikProps<Values>) => (
        <form onSubmit={props.handleSubmit}>
          <p className="text-sm text-center my-6">Forgot password? Please, write your email below - we will send you the link to restore your password.</p>
          <div className="flex flex-col gap-y-6">
            <Input
              name="email"
              type="email"
              label="Email"
            />
          </div>
          <Button
            disabled={props.isSubmitting}
            className="w-full"
          >
            Forgot Password
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
