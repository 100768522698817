import React from 'react';
import { Outlet } from 'react-router-dom';

const UnauthorizedLayout: React.FC = () => {
  return (
    <div className="grid place-items-center h-screen w-screen bg-dark-1">
      <div className="m-w-[364px] bg-dark-3 rounded-xl px-8 pt-6 pb-6 text-white">
        <Outlet />
      </div>
    </div>
  );
};

export default UnauthorizedLayout;
