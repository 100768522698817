import React, { useEffect, useRef, useState } from 'react';

interface DropdownOption {
  label: string;
  value: string | number;
}

interface Props {
  options: DropdownOption[];
  selectedVal: string | number;
  placeholder?: string;
  handleChange: (value: string, label: string) => void;
  name: string;
  className?: string;
}

const SearchableDropdown: React.FC<Props> = ({
  options,
  selectedVal,
  placeholder = 'Type to search...',
  handleChange,
  name,
  className = ''
}) => {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const lastPassIconRoot = document.querySelector('[data-lastpass-icon-root]');
      const lastPassRoot = document.querySelector('[data-lastpass-root]');
      if (lastPassRoot) {
        try {
          lastPassRoot.remove();
        } catch (error) {
          console.error('Error removing LastPass element:', error);
        }
      }
      if (lastPassIconRoot) {
        try {
          lastPassIconRoot.remove();
        } catch (error) {
          console.error('Error removing LastPass element:', error);
        }
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', toggle);
    return () => {
      document.removeEventListener('click', toggle);
    };
  }, []);

  const toggle = (e: MouseEvent): void => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const selectOption = (option: DropdownOption): void => {
    // setQuery(option[labelField]);
    setIsTyping(false);
    handleChange(option.value.toString(), option.label);
    setIsOpen(false);
  };

  const getDisplayValue = (): string => {
    if (isTyping) {
      return query;
    } else {
      const selectedOption = options.find((option) => option.value === selectedVal);
      return selectedOption ? selectedOption.label : '';
    }
  };

  const filteredOptions = options.filter(
    (option) => option.label.toLowerCase().includes(query.toLowerCase())
  );

  const toggleDropdown = (): void => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <div className={`dropdown ${className}`}>
      <div className="control">
        <div
          className="selected-value"
          onClick={toggleDropdown}
        >
          <input
            name={name}
            autoComplete="off"
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            placeholder={placeholder}
            onChange={(e) => {
              setQuery(e.target.value);
              setIsTyping(true);
              if (e.target.value === '') {
                handleChange('', '');
              }
              if (!isOpen) {
                setIsOpen(true);
              }
            }}
          />
          <div className={`arrow ${isOpen ? 'open' : ''}`}></div>
        </div>
      </div>

      {isOpen && (
        <div
          id="searchable-dropdown"
          className="options slide-down"
        >
          {filteredOptions.map((option, index) => (
            <div
              key={`${option.value}-${index}`}
              className={`option ${option.value === selectedVal ? 'selected' : ''}`}
              onClick={() => { selectOption(option); }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
