import React, { type ReactElement, useEffect, useRef, useState, useCallback, useContext } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import axios from 'axios';
import { CircularProgress, CssBaseline, ThemeProvider, Tooltip, createTheme } from '@mui/material';
import '../../../../index.css';
import {
  iconColorMap,
  iconTypeMapping
} from '../../../../helpers/types';
import TransactionDetailsModal from '../transaction-details/TransactionDetails.modal';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import { startCase } from 'lodash';
import TransactionGroupDetailsModal from '../transaction-details/TransactionGroupsDetails.modal';
import { appState } from '../../../../index';
import { getTransformedTransactions } from '../../../../hooks/useTransactions';
import { t } from 'i18next';
import { renderIconCell } from '../../../../helpers/columns';
import { fetchUniqueAccountToFcp } from '../../../../helpers/api';
import { type AccountToFcp } from '../../../../types/fcp.d';
import { TransactionsContext } from '../../../../contexts/transactionsContext';
import {
  type NestedTransactionDiscrepancyResource,
  type TransformedTransaction,
  type TransformedTransactionGroup
} from '../../../../types/transactions.d';

interface Props {
  activeUserId?: number | null;
  worstOffenders?: any;
  onHide: () => void;
  show: boolean;
  setWorstOffenders: any;
}

const MostExceptionsDetailsModal: React.FC<Props> = ({
  activeUserId,
  worstOffenders,
  onHide,
  show,
  setWorstOffenders
}) => {
  const activeUser = worstOffenders?.find((user: any) => user.id === activeUserId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [hasAllTransactionData, setHasAllTransactionData] = useState(false);
  const [headerField, setHeaderField] = useState(t('Details'));
  const [loading, setLoading] = useState(false);
  const { transactions, setTransactions } = useContext(TransactionsContext);

  // For Debugging State
  // useEffect(() => {
  //   console.log(activeUser);
  // }, [activeUser]);

  const fetchTransactions = useCallback(async (): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/get-most-exceptions-transactions`;

    const getHeaderField = (field: string): string => {
      const header = startCase(field.replace('_', ' '));
      return t(header);
    };

    try {
      const btParams = {
        user_id: activeUserId,
        account_id: appState.accountId.value,
        transactionIds: activeUser?.transactionIds
      };
      setLoading(true);
      const transactionsResponse = await axios.post(url, btParams);

      const uniqueFuelCardProviders: AccountToFcp[] | null = await fetchUniqueAccountToFcp(appState.accountId.value);
      if (uniqueFuelCardProviders) {
        if (uniqueFuelCardProviders?.length > 1) {
          setHeaderField(getHeaderField('Unique Field'));
        } else {
          setHeaderField(getHeaderField(uniqueFuelCardProviders[0]?.uniqueField));
        }
      }

      if (transactionsResponse.data.data) {
        // Update transactions and row count
        const transformedTransactions = getTransformedTransactions(transactionsResponse.data.data);
        setTransactions(transformedTransactions);
        setHasAllTransactionData(true);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
    setLoading(false);
  }, [activeUserId]);

  useEffect(() => {
    if (!show || activeUserId === null || activeUserId === undefined) {
      setTransactions([]);
      return;
    }
    void fetchTransactions();
  }, [show, activeUserId, fetchTransactions]);

  const renderIconSection = (field: string, count: number | null, tooltip: string): ReactElement => {
    const iconName: IconName = iconTypeMapping[field];
    const iconColor = iconColorMap[iconName as keyof typeof iconColorMap];

    return (
      <Tooltip title={t(tooltip)}>
        <div className="flex items-center gap-x-2">
          <div className={`${iconColor} w-[1.1rem] flex items-center justify-center`}>
            <Icon name={iconName}/>
          </div>
          <p>{count ?? 0}</p>
        </div>
      </Tooltip>
    );
  };
  const reloadBadTransactions = useCallback(async () => {
    setLoading(true);

    fetchTransactions().then(() => {
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [fetchTransactions]);

  const removeDismissedMostExceptionGroupTransaction = (groupId: number): void => {
    // Remove row from MostExceptionsModal
    setTransactions((prevTransactions) => {
      return prevTransactions.filter((transaction: TransformedTransaction | TransformedTransactionGroup): boolean => {
        return transaction.id !== groupId;
      });
    });

    const isInstanceOfTransformedTransactionGroup = (transaction: TransformedTransaction | TransformedTransactionGroup): transaction is TransformedTransactionGroup => {
      return (transaction as TransformedTransactionGroup).kind === 'transactionGroup';
    };

    // Find the correct group transaction
    const groupTransaction: TransformedTransactionGroup | undefined = transactions.find((transaction: TransformedTransaction | TransformedTransactionGroup) => {
      return isInstanceOfTransformedTransactionGroup(transaction) && transaction.id === groupId;
    }) as TransformedTransactionGroup;

    // Find the correct transactions within to the group transaction
    const nestedTransactions: NestedTransactionDiscrepancyResource[] | undefined = groupTransaction?.transactions;
    if (groupTransaction === undefined || nestedTransactions === undefined) {
      console.error('Error finding group transaction to dismiss');
      return;
    }

    // Update the Exceptions Dashboard state, including the type counts from the dashboard and the Exceptions Modal (they share the same type state)
    setWorstOffenders((prevOffenders: any[]) => {
      // Get the correct offender based on the groupId value within groupTransactions
      return prevOffenders.filter((offender: any) => {
        // Check if the offender has the group we're looking for
        if ('groupTransactions' in offender && groupId in offender.groupTransactions) {
          const nestedTransactionIds = offender.groupTransactions[groupId];

          // Remove the transaction ID's that are a part of the group from the transactionIds array which contains all the
          // transaction ID's of the worst offender both group and single
          offender.transactionIds = offender.transactionIds.filter((transactionId: number) => {
            return !nestedTransactionIds.includes(transactionId);
          });

          // Update the discrepancy count
          nestedTransactions.forEach((nestedTransaction: any) => {
            if (nestedTransaction.type in offender.typeCounts) {
              // Note: Transactions within groups are dynamically set to be fuel level mismatches before being sent over. It's not stored in the database this way though
              offender.typeCounts[nestedTransaction.type]--;
              offender.typeCounts.total_count--;

              nestedTransaction.subtypes.forEach((subtype: string) => {
                if (subtype in offender.typeCounts) {
                  offender.typeCounts[subtype]--;
                  offender.typeCounts.total_count--;
                }
              });
            }
          });

          // Delete the groupTransactions array from the offender after removing the transaction id's above
          delete offender.groupTransactions;

          // Remove the offender from the Exceptions Dashboard when all transactionId's array is empty
          if (!offender.transactionIds.length) {
            onHide();
            return false;
          }
        }
        return true;
      });
    });
  };

  const removeDismissedMostExceptionTransaction = (id: number, transaction: any): void => {
    // Remove row from MostExceptionsModal
    setTransactions([...transactions.filter((transaction: any): boolean => {
      return transaction.id !== id;
    })]);

    // Remove the worst offender from the dashboard
    setWorstOffenders((prevData: any) => prevData.filter((worstOffender: any) => {
      // Dismiss individual transactions
      worstOffender.transactionIds = worstOffender.transactionIds.filter((transactionId: number) => {
        if (id === transactionId) {
          worstOffender.typeCounts[transaction.type]--;
          worstOffender.typeCounts.total_count--;
          transaction.subtypes.forEach((subtype: any) => {
            if (transaction.id === transactionId) {
              worstOffender.typeCounts[subtype]--;
              worstOffender.typeCounts.total_count--;
            }
          });
        }
        return transactionId !== id;
      });
      if (!worstOffender.transactionIds.length) {
        onHide();
        return null;
      }
      return worstOffender;
    }));
  };

  const columns = [
    {
      field: 'icon',
      headerName: t('Icon'),
      width: 100,
      renderCell: renderIconCell
    },
    {
      field: 'details',
      headerName: headerField,
      width: 155,
      renderCell: (params: any) => {
        const { kind } = params.row;
        if (kind === 'transactionGroup') {
          return (
            <TransactionGroupDetailsModal
              transactionGroup={params.row}
              removedDismissedTransaction={removeDismissedMostExceptionGroupTransaction}
            />
          );
        }
        return (
          <TransactionDetailsModal
            transaction={params.row}
            isLoading={loading}
            removedDismissedTransaction={removeDismissedMostExceptionTransaction}
          />
        );
      },
      valueGetter: (params: any) => {
        if (params) {
          return params.row.uniqueId;
        } else {
          return '';
        }
      }
    },
    { field: 'totalCost', headerName: t('Total Cost'), width: 150 },
    { field: 'numUnits', headerName: t('Number of Units'), width: 150 },
    { field: 'date', headerName: t('Date'), width: 150 }
  ];
  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

  return (
    <>
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="users-line"/>
            <p>{t('Most Exceptions Details')}</p>
          </div>
        )}
        maxWidth="65rem"
        show={show}
        onHide={onHide}
      >
        {isSubmitting && (
          <div className="loading-modal-overlay">
            <CircularProgress/>
          </div>
        )}
        <div className="flex gap-x-6 mt-3">
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div ref={gridRef}>
              {loading && (
                <div
                  className="p-4 rounded-xl absolute w-full h-full right-[0px] bottom-[0px] flex justify-center items-center loading bg-opacity-50 bg-black"
                >
                  <CircularProgress/>
                </div>
              )}
              <DataGrid
                localeText={{
                  noRowsLabel: t('No rows'),
                  toolbarColumns: t('Columns'),
                  toolbarColumnsLabel: t('Columns'),
                  toolbarDensity: t('Density'),
                  toolbarDensityLabel: t('Density'),
                  toolbarDensityCompact: t('Compact'),
                  toolbarDensityStandard: t('Standard'),
                  toolbarDensityComfortable: t('Comfortable'),
                  toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                  MuiTablePagination: {
                    labelRowsPerPage: t('Rows per Page'),
                    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                  }
                }}
                rows={hasAllTransactionData ? transactions : []}
                columns={columns}
                hideFooterPagination
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: false,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true }
                  }
                }}
                className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                sx={{
                  height: '30rem',
                  '& .MuiDataGrid-row': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    marginTop: '4px',
                    borderBottom: 'none'
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell': {
                    border: 'none'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    borderBottom: 'none',
                    marginBottom: '10px'
                  },
                  border: 0
                }}
              />
            </div>
          </ThemeProvider>
          <div className="bg-dark-1 w-px"/>
          <div className="flex-grow flex flex-col gap-y-8">
            <div className="grid grid-cols-2 gap-3">
              {renderIconSection('group', activeUser?.typeCounts.group, 'Transaction Group')}
              {renderIconSection('no_vehicle_location', activeUser?.typeCounts.no_vehicle_location, 'No Vehicle Location')}
              {renderIconSection('location_mismatch', activeUser?.typeCounts.location_mismatch, 'Location Mismatch')}
              {renderIconSection('fuel_level_mismatch', activeUser?.typeCounts.fuel_level_mismatch, 'Fuel Level Mismatch')}
              {renderIconSection('fuel_type_mismatch', activeUser?.typeCounts.fuel_type_mismatch, 'Fuel Type Mismatch')}
              {renderIconSection('filling_time_alert', activeUser?.typeCounts.filling_time_alert, 'Filling Time Alert')}
              {renderIconSection('filling_percent_alert', activeUser?.typeCounts.filling_percent_alert, 'Filling Percent Alert')}
            </div>
            <div>
              <p className="font-light">{t('Driver')}</p>
              <p className="font-bold">{activeUser?.driver}</p>
            </div>
            <div>
              <p className="font-light">{t('Total Money in Dispute')}</p>
              <p className="font-bold">${activeUser?.money.toFixed(2)}</p>
            </div>
            <div>
              <p className="font-light">{t('Total Fuel in Dispute')}</p>
              <p className="font-bold">{activeUser?.fuel.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MostExceptionsDetailsModal;
