import React from 'react';
import Icon from '../Icon';
import { refreshLogin } from '../../../index';

const SidebarFooter: React.FC = () => {
  return (
    <div className="bg-dark-2 flex items-center gap-x-3 p-3 rounded-lg shadow-md">
      <button
        className="leading-none"
        onClick={refreshLogin}
        title="Refresh Login"
      >
        <Icon
          name="refresh"
          className="text-custom-red-1"
          size="xs"
        />
      </button>
    </div>
  );
};

export default SidebarFooter;
