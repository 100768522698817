import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, type SizeProp, type IconName } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

library.add(fal);
library.add(fat);
library.add(far);
library.add(fas);
library.add(fad);

type FontTypes = 'fas' | 'fal' | 'far' | 'fab' | 'fad';

interface Props {
  name: IconName;
  type?: FontTypes;
  size?: SizeProp;
  className?: string;
}

const Icon: React.FC<Props> = ({
  name,
  type = 'fas',
  size = 'sm',
  className = ''
}) => {
  return (
    <FontAwesomeIcon
      icon={[type, name]}
      size={size}
      className={className}
    />
  );
};

export default Icon;
