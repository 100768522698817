import React from 'react';
import Icon from '../ui/shared/Icon';
import { Tooltip } from '@mui/material';
import { iconColorMap, type TransactionType, transactionTypeFormat } from './types';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { t } from 'i18next';

export const renderIconCell = (params: any): any => {
  const iconClassName: string = iconColorMap[params.value as keyof typeof iconColorMap];
  let iconName: string = t(transactionTypeFormat[params.row.type as TransactionType || 'warning' as IconName]);
  const subTypeNames: string = params.row.subtypes?.length
    ? params.row.subtypes.map((type: string) => t(transactionTypeFormat[type as TransactionType])).join().replace(',', ', ')
    : '';
  if (subTypeNames) {
    iconName = `${iconName}` + ', ' + subTypeNames;
  }
  return (
    <Tooltip title={iconName}>
      <div>
        <Icon
          name={params.value || 'warning'}
          size="lg"
          className={iconClassName}
        />
      </div>
    </Tooltip>
  );
};

export const isValidCell = (value: any): boolean => {
  if (value === null || value === undefined || value === '' || value === ' ') {
    return false;
  }
  return true;
};
