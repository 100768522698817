import { type Signal, signal } from '@preact/signals';

export const gasStationIcon: Signal<string> = signal<string>(localStorage.getItem('gasStationIcon') ?? '/assets/images/main/gas_station_marker.png');
export const vehicleIcon: Signal<string> = signal<string>(localStorage.getItem('vehicleIcon') ?? '/assets/images/main/vehicle_logo.png');
export const mainLogo: Signal<string> = signal<string>(localStorage.getItem('logo') ?? '/assets/images/main/main_logo.png');
export const favicon: Signal<string> = signal<string>(localStorage.getItem('favicon') ?? '/assets/images/main/favicon.ico');

export const setCustomerAssets = (accountName: string): void => {
  let folderName = 'main';

  switch (accountName) {
    case 'Advantage Asset Tracking':
      folderName = 'Advantage';
      break;
    case 'Mobile Fleet Solutions':
      folderName = 'MFS';
      break;
    case 'USA Fleet':
      folderName = 'USA-Fleet';
      break;
    default:
      folderName = 'main';
      break;
  }
  // Update local storage
  localStorage.setItem('gasStationIcon', `/assets/images/${folderName}/gas_station_marker.png`);
  localStorage.setItem('vehicleIcon', `/assets/images/${folderName}/vehicle_marker.png`);
  localStorage.setItem('logo', `/assets/images/${folderName}/main_logo.png`);
  localStorage.setItem('favicon', `/assets/images/${folderName}/favicon.ico`);

  // Update signals
  gasStationIcon.value = `/assets/images/${folderName}/gas_station_marker.png`;
  vehicleIcon.value = `/assets/images/${folderName}/vehicle_marker.png`;
  mainLogo.value = `/assets/images/${folderName}/main_logo.png`;
  favicon.value = `/assets/images/${folderName}/favicon.ico`;
};
