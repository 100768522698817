import React, { useState } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Button from '../../Button';
import Input from '../../Formik/Input';
import { Formik } from 'formik';
import axios from 'axios';
import { type GridRenderCellParams } from '@mui/x-data-grid';

interface Props {
  params: GridRenderCellParams;
  updateUser: (userId: number, id: number) => Promise<void>;
}

const ChangePrimaryUserModal: React.FC<Props> = ({ params, updateUser }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [userNames, setUserNames] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const getUsers = async (): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}` +
      `/api/users?include=userSettings&filter=account_id:${params.row.id}(eq)`;
    try {
      const response = await axios.get(url);
      if (response.data.data) {
        const names = response.data.data.map((user: any): string => {
          return (
            user.name
          );
        });
        setUserNames(names);
        setUsers(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onHide = (): void => {
    setIsShown(false);
  };

  const getUserNameByID = (name: number): void => {
    users.forEach((user: any): void => {
      if (user.name === name) {
        void updateUser(user.id, params.row.id);
      }
    });
  };

  return (
    <>
      <button
        style={{ margin: '0' }}
        className="transition text-custom-blue-normal hover:text-custom-blue-hover outline-none"
        onClick={(): void => {
          setIsShown(true);
          void getUsers();
        }}
        type="button"
      >
        {params.row.primaryUser}
      </button>
      {isShown && <Modal
          header={(
            <div className="flex items-center gap-x-2">
              <Icon name="gear"/>
              <p>Change Primary User</p>
            </div>
          )}
          show={isShown}
          onHide={onHide}
          maxWidth={350}
      >
          <Formik
              enableReinitialize
              initialValues={{
                primaryUser: params.row.primaryUser
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  getUserNameByID(values.primaryUser);
                  params.row.primaryUser = values.primaryUser;
                  setSubmitting(false);
                  onHide();
                }, 400);
              }}
          >
            {(props: any) => {
              return (
                <form
                  onSubmit={props.handleSubmit}
                  className="mt-4"
                >
                  <div className="flex flex-col gap-y-6">
                    <Input
                      name="primaryUser"
                      type="select"
                      label="New Primary User"
                      defaultValue={params.row.primaryUser}
                      options={userNames}
                    />
                  </div>
                  <div className="text-left">
                    <Button
                      disabled={props.isSubmitting}
                      className="!px-12"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
      </Modal>}
    </>
  );
};

export default ChangePrimaryUserModal;
