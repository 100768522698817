import React, { useState, useEffect } from 'react';
import Icon from '../../../ui/shared/Icon';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Switch from '../../../ui/shared/Switch';
import Button from '../../../ui/shared/Button';
import { appState, loginAsChild, logoutChild } from '../../../index';
import { openToast } from '../../../helpers/toast';
import { formatLoginResponse } from '../../Unauthorized/Login/Login';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const Resellers: React.FC = () => {
  const [accounts, setAccounts] = useState<any>([]);
  const [user, setUser] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const handleLoginAsChild = async (account: any): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/login-as-child`;
    try {
      const loginAsChildResponse = await axios.post(url, { account_id: account.id });
      loginAsChild(formatLoginResponse(loginAsChildResponse.data, 'en'));
    } catch (err) {
      console.log(err);
      openToast({ type: 'error', label: 'Account unauthorized', autoClose: 2000, theme: 'dark' });
    }
  };

  const getAccounts = async (): Promise<void> => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/accounts?filter=parent_account_id:${appState.accountId.value}(eq)&include=accountSettings`;
    try {
      const response = await axios.get(url);
      setAccounts(await transformData(response.data.data, user));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async (): Promise<void> => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${appState.userId}(eq)`;
    try {
      const response = await axios.get(url);
      setUser(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getFuelCards = async (): Promise<any> => {
    const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards`;
    try {
      const response = await axios.get(url);
      const count: any = {};
      response.data.data.forEach((obj: any) => {
        if (Object.prototype.hasOwnProperty.call(obj, 'account_id')) {
          count[obj.account_id] = Number((count[obj.account_id] || 0)) + 1;
        }
      });
      return count;
    } catch (err) {
      console.log(err);
    }
  };

  const transformData = async (accounts: any, userSettings: any): Promise<any> => {
    if (!accounts || !userSettings) {
      return [];
    }
    const fuelCardsCount: any = await getFuelCards();
    return accounts.map((account: any) => (
      {
        id: account.id,
        accountName: account.name,
        pairType: account.account_settings?.preferred_match
          ? (String(account.account_settings.preferred_match.charAt(0).toUpperCase()) +
            String(account.account_settings.preferred_match.slice(1)))
          : '',
        unitOfMeasure: userSettings?.unit_of_measure
          ? (String(userSettings.unit_of_measure.charAt(0).toUpperCase()) +
            String(userSettings.unit_of_measure.slice(1)))
          : '',
        fixedIFTA: account.account_settings?.fix_ifta,
        useAnnotations: account.account_settings?.use_annotations,
        cards: fuelCardsCount[account.id] ?? 0,
        primaryUser: account.primary_user_id,
        accountLevel: account.account_level,
        accountSettingsId: account?.account_settings?.id
      }
    ));
  };

  useEffect(() => {
    logoutChild();
    void getAccounts();
    void getUser();
  }, []);

  const changeUnitOfMeasure = async (item: any, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/user-settings/${id}`;
    const params = {
      unit_of_measure: item.toLowerCase()
    };
    try {
      const response = await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleFixIFTA = async (newValue: boolean, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings/${id}`;
    const params = {
      fix_ifta: newValue
    };
    try {
      const response = await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleUseAnnotations = async (newValue: boolean, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings/${id}`;
    const params = {
      use_annotations: newValue
    };
    try {
      const response = await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: 'accountName',
      headerName: t('Account Name'),
      width: 250
    },
    {
      field: 'fixedIFTA',
      headerName: t('Fixed IFTA'),
      width: 120,
      renderCell: (cell: any) => {
        return (
          <Switch
            key={'fixedIFTA' + `${cell.row.id}`}
            checked={cell.row.fixedIFTA}
            onClick={(newValue: boolean) => {
              void toggleFixIFTA(newValue, cell.row.accountSettingsId);
              setAccounts(accounts.map((currentAccount: any) => {
                if (currentAccount.id === cell.row.id) {
                  return {
                    ...currentAccount,
                    fixedIFTA: newValue
                  };
                }
                return currentAccount;
              }));
            }}
          />
        );
      }
    },
    {
      field: 'useAnnotations',
      headerName: t('Use Annotations'),
      width: 150,
      renderCell: (cell: any) => {
        return (
          <Switch
            key={'useAnnotations' + `${cell.row.id}`}
            checked={cell.row.useAnnotations}
            onClick={(newValue: boolean) => {
              void toggleUseAnnotations(newValue, cell.row.accountSettingsId);
              setAccounts(accounts.map((currentAccount: any) => {
                if (currentAccount.id === cell.row.id) {
                  return {
                    ...currentAccount,
                    useAnnotations: newValue
                  };
                }
                return currentAccount;
              }));
            }}
          />
        );
      }
    },
    {
      field: 'cards',
      headerName: t('Cards'),
      width: 80
    },
    {
      field: 'action',
      headerName: '',
      width: 170,
      renderCell: (cell: any) => {
        return (
          <Button
            key={'action' + `${cell.row.id}`}
            outline
            variant="primary"
            type="button"
            className="!mt-0"
            onClick={() => {
              void handleLoginAsChild(cell.row).then();
            }}
          >
            {t('Login')}
          </Button>
        );
      }
    }
  ];

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center loading items-center bg-opacity-50 bg-black"
        >
          <CircularProgress/>
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="table-columns"/>
            <span>{t('Reseller Dashboard')}</span>
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <DataGrid
            hideFooter={true}
            localeText={{
              noRowsLabel: t('No rows'),
              toolbarColumns: t('Columns'),
              toolbarColumnsLabel: t('Columns'),
              toolbarDensity: t('Density'),
              toolbarDensityLabel: t('Density'),
              toolbarDensityCompact: t('Compact'),
              toolbarDensityStandard: t('Standard'),
              toolbarDensityComfortable: t('Comfortable'),
              toolbarQuickFilterPlaceholder: `${t('Search')}...`,
              MuiTablePagination: {
                labelRowsPerPage: t('Rows per Page'),
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
              }
            }}
            pagination
            rowCount={accounts.length}
            rows={[...accounts].sort((accountA: any, accountB: any) => {
              const keyA = accountA.accountName.toLowerCase();
              const keyB = accountB.accountName.toLowerCase();
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            })}
            paginationMode="server"
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: { // Disable Export Option
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            density="compact"
            disableDensitySelector
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '50rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default withTranslation()(Resellers);
