import { type Context, createContext, type Dispatch, type SetStateAction } from 'react';
import { type TransformedTransactionGroup, type TransformedTransaction } from '../types/transactions.d';

export interface TransactionsContextType {
  transactions: Array<TransformedTransaction | TransformedTransactionGroup>;
  setTransactions: Dispatch<SetStateAction<Array<TransformedTransaction | TransformedTransactionGroup>>>;
}

const defaultTransactionsContext: TransactionsContextType = {
  transactions: [],
  setTransactions: () => {
  }
};

// Context holds the required transactions for the current page
// Pages in use: Transactions, Dismissed Transactions, Fuelling Efficiency...
export const TransactionsContext: Context<TransactionsContextType> = createContext<TransactionsContextType>(defaultTransactionsContext);
