/* eslint-disable */

export type TransactionType =
    'fuel_level_mismatch' |
    'location_mismatch' |
    'no_vehicle_location' |
    'fuel_type_mismatch' |
    'filling_time_alert' |
    'filling_percent_alert' |
    'multiple' |
    'none' |
    'group';

export const isValidTransactionType = (type: string): type is TransactionType => {
    const validTypes: TransactionType[] = [
        'fuel_level_mismatch',
        'location_mismatch',
        'no_vehicle_location',
        'fuel_type_mismatch',
        'filling_time_alert',
        'filling_percent_alert',
        'multiple',
        'group'
    ];
    return validTypes.includes(type as TransactionType);
}
export const iconTypeMapping: Record<string, IconName> = {
    fuel_level_mismatch: 'gas-pump-slash',
    location_mismatch: 'location-pin-slash',
    no_vehicle_location: 'cloud-slash',
    fuel_type_mismatch: 'droplet-percent',
    filling_time_alert: 'hourglass-clock',
    filling_percent_alert: 'percent',
    multiple: 'warning',
    none: 'square-question',
    group: 'layer-group'
};

export const transactionTypeToHeader: any = {
    fuel_level_mismatch: 'Fuel Unaccounted For',
    location_mismatch: 'Distance',
    no_vehicle_location: 'Vehicle Name',
    fuel_type_mismatch: 'Fuel Type',
    filling_time_alert: 'Filling Time',
    filling_percent_alert: 'Filling %'
}

export const transactionTypeFormat: Record<TransactionType, string> = {
    'fuel_level_mismatch': 'Fuel Level Mismatch',
    'location_mismatch': 'Location Mismatch',
    'no_vehicle_location': 'No Vehicle Location',
    'fuel_type_mismatch': 'Fuel Type Mismatch',
    'filling_time_alert': 'Filling Time Alert',
    'filling_percent_alert': 'Filling Percent Alert',
    'multiple': 'Multiple Alert Types',
    'none': 'No Alert Type',
    'group': 'Group Transaction'
} 

export type TransactionGroup = {
    transactions: Transaction[];
};

export type IconName =
    'gas-pump-slash'
    | 'cloud-slash'
    | 'location-pin-slash'
    | 'droplet-percent'
    | 'percent'
    | 'hourglass-clock'
    | 'warning'
    | 'square-question'
    | 'layer-group';

export const iconColorMap: Record<IconName, string> = {
    'gas-pump-slash': 'text-custom-red-1',
    'cloud-slash': 'text-error-2',
    'location-pin-slash': 'text-custom-red-1',
    'droplet-percent': 'text-custom-red-1',
    'percent': 'text-error-1',
    'hourglass-clock': 'text-error-1',
    'warning': 'text-error-1',
    'square-question': 'text-error-2',
    'layer-group': 'text-custom-red-1',
};


export type TransactionModalData = {
    annotationId?: any;
    converted_datetime?: string;
    account?: any;
    transaction_group_id?: any;
    transaction_id?: any;
    fuel_level_difference: string | any;
    filling_percent: any | string;
    icon: IconName;
    gas_station: {
        zip: any;
        state: any;
        city: any;
        address: any;
        name: any;
        gas_station_id: any;
        latitude: any;
        longitude: any;
    };
    vehicle_location: {
        latitude: any;
        longitude: any;
    };
    fuel_type: string;
    cost_per_unit: string | any;
    driver_name: string | any;
    name_on_card: string | any;
    filling_time: number | any;
    vehicle_id: number | any;
    vehicle_name: string | any;
    vehicle_groups: string[] | any;
    type: TransactionType;
    id: number;
    name: string;
    total_cost: any;
    num_units: string;
    date: string;
    time: string;
    unique_id: string | any;
    distance: number | string;
    accountId: string | null;
    reloadBadTransactions: () => void;
    timestamp: number | any;
    subtypes: any;
}

export type Transaction = {
    id: number;
    account_id: string | null;
    geotab_vehicle_id: number;
    fuel_card_id: number;
    transaction_group_id: number | null;
    vehicle_id: string;
    gps_driver_id: number | null;
    driver_from_transactions_id: number;
    fcp: string;
    unformatted_timestamp: string;
    timestamp: number;
    original_timestamp: number;
    is_oon: number;
    transaction_id: string;
    vin: string;
    odometer: number | null;
    driver_fname: string;
    driver_mname: string;
    driver_lname: string;
    name_on_card: string;
    card_pin: string;
    vehicle_number: string | null;
    driver_id: string;
    gas_station_id: number;
    merchant_ticket_id: string;
    currency_type: string;
    fuel_type: string;
    fuel_type_abbr: string;
    transaction_unit_of_measure: string;
    cost_per_unit: string;
    num_units: string;
    total_cost: number;
    vehicle_latitude: string;
    vehicle_longitude: string;
    distance: number;
    pre_filling_fuel_percent: number;
    pre_filling_fuel_level: number;
    post_filling_fuel_level: number;
    fuel_amount_put_in: number;
    filling_time: number | null;
    time: number;
    day: string;
    marked_good: number;
    marked_sus: number;
    finished_processing: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    type: string;
    subtypes: any[];
    fuel_level_difference: string;
    datetime: string;
    formatted_filling_time: string;
    fuel_card?: {
        id: number;
        account_id: number;
        fcp: string;
        driver_id: number | null;
        driver_resource_id: number | null;
        vehicle_id: string;
        geotab_vehicle_id: number;
        fuel_type: string;
        unique_identifier: string;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
    };
    gas_station?: {
        id: number;
        name: string;
        address: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        longitude: string;
        latitude: string;
        timezone: string;
        galaxy_pump_id: number | null;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        corrected_gas_station: any[];
    };
    transaction_group?: TransactionGroup | null;
    account?: {
        id: number;
        parent_account_id: number | null;
        primary_user_id: number;
        geotab_user_id: number;
        gps_service: string;
        use_default_fuel_type: number;
        name: string;
        account_level: string;
        status: string;
        region: string;
        renewal_date: string;
        mygeotab_database_guid: string;
        push_to_geotab: number;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        account_settings?: {
            id: number;
            account_id: number;
            email_process_file_errors: {
                fuel_transaction: boolean;
                fuel_card: boolean;
            };
            preferred_match: string;
            ignore_oon: number;
            distance_threshold: string;
            fuel_difference_threshold: string;
            fuel_difference_threshold_unit: string;
            filling_percent_threshold: number;
            filling_time_threshold: number;
            fix_ifta: number;
            use_annotations: number;
            unit_of_measure: string;
            fi_start_time: number;
            fi_end_time: number;
            days: string[];
            created_at: string;
            updated_at: string;
            deleted_at: string | null;
        };
    };
};

export type TransactionAlertName = 'no_vehicle_location' | 'location_mismatch' | 'fuel_level_mismatch' | 'fuel_type_mismatch' | 'filling_time_alert' | 'filling_percent_alert';

export interface TransactionAlerts {
    no_vehicle_location: boolean;
    location_mismatch: boolean;
    fuel_level_mismatch: boolean;
    fuel_type_mismatch: boolean;
    filling_time_alert: boolean;
    filling_percent_alert: boolean;
}

export const defaultTransactionAlerts: TransactionAlerts = {
    no_vehicle_location: false,
    location_mismatch: false,
    fuel_level_mismatch: false,
    fuel_type_mismatch: false,
    filling_time_alert: false,
    filling_percent_alert: false
}
