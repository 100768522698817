import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import Switch from '../Switch';

interface Props {
  label: React.ReactNode;
  name: string;
  switchType?: boolean;
  defaultChecked?: boolean;
  onChange?: (newValue: boolean) => void;
}

const Checkbox: React.FC<Props> = ({ label, name, switchType = false, defaultChecked, onChange }) => {
  const [field, meta, { setValue }] = useField({
    name
  });

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-x-2">
        <input
          {...field}
          name={name}
          type="checkbox"
          id={name}
          onChange={() => {
            // eslint-disable-next-line
            setValue(!field.value);
          }}
          className={classNames(switchType && 'opacity-0 absolute')}
        />
        {switchType && (
          <Switch
            checked={!!field.value}
            onClick={(newValue: boolean) => {
              // eslint-disable-next-line
              setValue(newValue);
            }}
          />
        )}
        <label
          htmlFor={name}
          className={classNames(switchType ? 'text-sm' : 'text-xs')}
        >
          {label}
        </label>
      </div>
      {meta.touched && meta.error && (
        <p className="text-red-600 text-xs">{meta.error}</p>
      )}
    </div>
  );
};

export default Checkbox;
