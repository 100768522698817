import React, { useState } from 'react';
import Icon from '../../../ui/shared/Icon';
import { t } from 'i18next';

const FuelCardSettingsTooltip: React.FC = () => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="w-min"
      onMouseEnter={() => { setShow(true); }}
      onMouseLeave={() => { setShow(false); }}
    >
      <Icon
        name="circle-info"
        className="cursor-pointer"
      />

      {show && (
        <div className="absolute flex flex-col gap-y-1 z-[9999] bg-dark-3 rounded-lg p-4 shadow-md translate-x-5 translate-y-[-19%] text-sm max-w-[140px]">
          <p>{t('If a fuel card is not found, the default pairing value is used to find and pair the correct fuel card')}</p>
        </div>
      )}

    </div>
  );
};

export default FuelCardSettingsTooltip;
