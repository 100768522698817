import React, { useState } from 'react';
import Icon from './Icon';

interface Props {
  children: React.ReactNode;
}

const ToolTip: React.FC<Props> = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div
      className="w-min"
      onMouseEnter={(): void => {
        setShow(true);
      }}
      onMouseLeave={(): void => {
        setShow(false);
      }}
    >
      <Icon
        name="circle-info"
        className="cursor-pointer"
      />

      {show && (
        <div
          className="absolute flex flex-col gap-y-1 z-[9999] bg-dark-3 rounded-lg p-4 shadow-md translate-x-[-5] translate-y-[-19%] text-sm max-w-[310px]"
        >
          {children}
        </div>
      )}

    </div>
  );
};

export default ToolTip;
