import { useEffect } from 'react';
import { appState, clearCredentials } from '../index';

const useiFrameMessages = (): void => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      // Check the target of the incoming message
      if (event.data.target === 'app') {
        if (event.data.type === 'currentDatabase') {
          const currentDatabase = event.data.data;
          console.log(`ssoGeotabDatabase: ${appState.ssoGeotabDatabase}, currentDatabase: ${JSON.stringify(currentDatabase)}`);
          if (appState.ssoGeotabDatabase !== null) {
            if (appState.ssoGeotabDatabase !== currentDatabase) {
              localStorage.setItem('ssoGeotabDatabase', currentDatabase);
              appState.ssoGeotabDatabase.value = currentDatabase;
              clearCredentials();
              window.location.href = '/';
            }
          } else {
            localStorage.setItem('ssoGeotabDatabase', currentDatabase);
          }
        }

        if (event.data.type === 'group_filter') {
          const realGroups = [];
          const groupFilter = event.data.data;
          for (let i = 0; i < groupFilter.length; i++) {
            if (groupFilter[i].id !== 'GroupCompanyId') {
              realGroups.push(groupFilter[i].id);
            }
          }
          if (realGroups.length > 0) {
            const groupValue = realGroups.join(',');
            localStorage.setItem('groupFilter', groupValue);
            appState.groupFilter.value = groupValue;
            window.location.reload();
          } else {
            localStorage.removeItem('groupFilter');
            appState.groupFilter.value = null;
            window.location.reload();
          }
        }
      }
    };

    window.addEventListener('message', handleMessage, false);

    // Handle cleanup
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [appState.groupFilter.value]);
};

export default useiFrameMessages;
