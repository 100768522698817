import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Button from '../../Button';
import IconButton from '../../IconButton';
import Switch from '../../../shared/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import { openToast } from '../../../../helpers/toast';
import { appState } from '../../../../index';
import Tooltip from '../../ToolTip';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import '../../../../index.css';
import { fetchGeotabDrivers, fetchGeotabVehicles } from '../../../../helpers/api';
import {
  type GeotabVehicle,
  type AccountToFcp,
  type FuelCardProvider,
  type PreferredMatch,
  type GeotabDriver
} from '../../../../types/fcp.d';
import { Form, Formik, type FormikProps } from 'formik';
import Input from '../../Formik/Input';
import * as Yup from 'yup';
import { formatOptions } from '../../../../helpers/fcp';

const iconButtonContainerStyle: React.CSSProperties = {
  position: 'relative',
  display: 'inline-flex', // Change this to inline-flex
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px', // Assuming this is the width of the IconButton. Adjust as needed
  height: '40px' // Assuming this is the height of the IconButton. Adjust as needed
};

const progressStyle: React.CSSProperties = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)'
};

interface ImportFuelCardsProps {
  onImportCompleted?: () => void; // Callback prop
  accountToFcpData: AccountToFcp[];
}

interface ImportFuelCardValues {
  fuelCardProvider: FuelCardProvider | null;
  fuelCardsImportFile: File | null;
}

type ImportResponseErrors = Record<string, number[]>;

const ImportFuelCards: React.FC<ImportFuelCardsProps> = ({
  onImportCompleted,
  accountToFcpData
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCompletedImport, setIsCompletedImport] = useState(false);
  const [importResponseErrors, setImportResponseErrors] = useState<ImportResponseErrors>({});
  const [importResponseSuccessCount, setImportResponseSuccessCount] = useState(null);
  const [maxWidth, setMaxWidth] = useState(400);
  const [maxHeight, setMaxHeight] = useState(500);
  const [fuelCardProviders, setFuelCardProviders] = useState<FuelCardProvider[]>([]);
  const [preferredMatch, setPreferredMatch] = useState<PreferredMatch | null>(null);
  const [uniqueIdentifierLabel, setUniqueIdentifierLabel] = useState<string | null>(null);
  const [selectedFuelCardProvider, setSelectedFuelCardProvider] = useState<FuelCardProvider | null>(null);
  const [allFuelCards, setAllFuelCards] = useState<boolean>(false);

  const importFuelCardsValidationSchema = Yup.object({
    fuelCardProvider: Yup.string().required(t('Required'))
  });

  const initialImportValues: ImportFuelCardValues = {
    fuelCardProvider: accountToFcpData.length === 1 ? accountToFcpData[0].fuelCardProvider as FuelCardProvider : null,
    fuelCardsImportFile: null
  };

  useEffect(() => {
    // Get providers from unique the accounts unique account to fcp record
    setFuelCardProviders(accountToFcpData.length
      ? accountToFcpData.map((fcp: AccountToFcp) => fcp.fuelCardProvider as FuelCardProvider)
      : []);

    // Accounts with one provider
    if (accountToFcpData.length === 1) {
      setPreferredMatch(accountToFcpData[0].preferredMatch);
      setUniqueIdentifierLabel(formatOptions(accountToFcpData[0].uniqueField));
      setSelectedFuelCardProvider(accountToFcpData[0].fuelCardProvider as FuelCardProvider);
    }
  }, [accountToFcpData]);

  const handleDownloadClick = async (): Promise<void> => {
    setIsDownloading(true);
    try {
      const fuelCardParams = {
        account_id: appState.accountId.value
      };
      const fuelCardsResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/fuel-cards-minimized`, fuelCardParams);
      const existingVehiclesResponse: GeotabVehicle[] | null = await fetchGeotabVehicles();
      const existingDriversResponse: GeotabDriver[] | null = await fetchGeotabDrivers();
      if (!existingDriversResponse) {
        return;
      }
      if (!existingVehiclesResponse) {
        return;
      }

      let csvData;
      let csvContent = '';
      const pairingType = preferredMatch ? preferredMatch.replace(/\b\w/g, (match) => match.toUpperCase()) : '';

      // Extract device IDs from fuel cards
      const existingFuelCardDeviceIDs = fuelCardsResponse.data.data
        .map((card: any) => card.geotab_vehicle?.device_id ?? card.geotab_driver?.device_id)
        .filter(Boolean);

      // If vehicle, filter out vehicles from existingVehicles that already have a fuel card
      if (pairingType === 'Vehicle') {
        let vehicles: GeotabVehicle[] = existingVehiclesResponse;
        if (!allFuelCards) {
          vehicles = vehicles.filter((vehicle: GeotabVehicle) => !existingFuelCardDeviceIDs.includes(vehicle.device_id));
        }
        console.log('vehicles:', vehicles);
        csvData = vehicles
          .map((vehicle: GeotabVehicle): string => {
            // Enclose vehicle.name in double quotes
            return `${vehicle.id},${vehicle.device_id},"${vehicle.name}",,${selectedFuelCardProvider ?? ''},,`;
          });

        csvContent = `Geotab Vehicle ID,ID,${pairingType},Fuel Type,Provider,${uniqueIdentifierLabel}\n${csvData.join('\n')}`;

        // Else if driver, filter out drivers from existingDrivers instead
      } else if (pairingType === 'Driver') {
        let drivers: GeotabDriver[] = existingDriversResponse;
        if (!allFuelCards) {
          drivers = drivers.filter((driver: GeotabDriver) => !existingFuelCardDeviceIDs.includes(driver.name));
        }
        csvData = drivers
          .map((driver: any) => {
            // Enclose driver.name in double quotes
            return `${driver.id},"${driver.name}",,${selectedFuelCardProvider ?? ''},,,`;
          });

        csvContent = `ID,${pairingType},Fuel Type,Provider,${uniqueIdentifierLabel}\n${csvData.join('\n')}`;
      }

      // CSV generation and download
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'fuel_cards.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      openToast({
        type: 'success',
        label: t('Download successful'),
        autoClose: 2000,
        theme: 'dark'
      });
    } catch (error) {
      openToast({
        type: 'error',
        label: t('Error during download'),
        autoClose: 2000,
        theme: 'dark'
      });
      console.error('Error generating template:', error);
    }
    setIsDownloading(false);
  };

  function checkFile (file: File): any {
    const validFileExtensions = ['.xlsx', '.xls', '.csv'];
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));

    if (!validFileExtensions.includes(fileExt)) {
      setErrorMessage(`${t('Invalid file selected, valid files types are: ')}` + `${validFileExtensions.join(', ')}`);
      return false;
    } else {
      setErrorMessage(null);
      return true;
    }
  }

  const handleFileUpload = async (): Promise<any> => {
    if (!selectedFile) {
      openToast({
        type: 'error',
        label: t('No file selected'),
        autoClose: 2000,
        theme: 'dark'
      });
      return;
    }
    if (!preferredMatch || !uniqueIdentifierLabel || !selectedFuelCardProvider) {
      openToast({
        type: 'error',
        label: t('Invalid provider'),
        autoClose: 2000,
        theme: 'dark'
      });
      return;
    }

    const file = selectedFile;

    // Create a FormData object to hold the file
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fcp_code', selectedFuelCardProvider);
    try {
      setIsUploading(true);
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/import/${appState.accountId.value}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        const messagesArray = Object.values(response.data.errors).map((item: any) => item.messages);
        openToast({
          type: response?.data?.success ? 'success' : 'error',
          label: t(response?.data?.success ? t('Import successful') : t('A Problem occurred during import')),
          autoClose: 2000,
          theme: 'dark'
        });
        // Save the response data in the state and open the modal
        handleErrorDisplaying(messagesArray);
        setImportResponseSuccessCount(response.data.successful_row_import_count);
        setIsCompletedImport(true);
        setMaxWidth(800);
        if (onImportCompleted) {
          onImportCompleted();
        }
      } else {
        openToast({
          type: 'error',
          label: t('Error uploading the file'),
          autoClose: 2000,
          theme: 'dark'
        });
        console.error('Error uploading the file');
      }
    } catch (error: AxiosError<any> | unknown) {
      if (error instanceof AxiosError) {
        openToast({
          type: 'error',
          label: t(error?.response?.data?.message ?? 'Error uploading the file'),
          autoClose: 2000,
          theme: 'dark'
        });
      } else {
        openToast({
          type: 'error',
          label: t('Error uploading the file'),
          autoClose: 2000,
          theme: 'dark'
        });
      }
      console.error('Error uploading the file:', error);
    }
    setIsUploading(false);
  };

  const handleFileChange = (file: File): any => {
    if (checkFile(file)) {
      setSelectedFile(file);
    }
  };

  const onHide = (): void => {
    setIsShown(false);
    setMaxWidth(400);
    setImportResponseErrors({});
    setImportResponseSuccessCount(null);
    setIsCompletedImport(false);
  };

  const handleErrorDisplaying = (errorData: any[]): void => {
    const consolidatedErrors: ImportResponseErrors = errorData.reduce((acc: ImportResponseErrors, row: any, index: number) => {
      const rowCount = row.split(' - ')[0];
      const key = row.replace(`${rowCount} - `, '');
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(rowCount.split(' ')[1]);
      return acc;
    }, {});
    setImportResponseErrors(consolidatedErrors);
  };

  const handleProviderChange = (provider: FuelCardProvider): void => {
    const accountToFcp: AccountToFcp | undefined = accountToFcpData.find((accountToFcp: AccountToFcp) => accountToFcp.fuelCardProvider === provider);
    if (accountToFcp) {
      setPreferredMatch(accountToFcp.preferredMatch);
      setUniqueIdentifierLabel(formatOptions(accountToFcp.uniqueField));
      setSelectedFuelCardProvider(accountToFcp.fuelCardProvider as FuelCardProvider);
    } else {
      console.error('No account to fcp record found for this provider');
    }
  };

  return (
    <>
      <IconButton
        icon="upload"
        onClick={() => {
          setIsShown(true);
        }}
      />
      <Modal
        show={isShown}
        onHide={onHide}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        header={
          <div className="flex items-center gap-x-2">
            <Icon name="upload"/>
            <p>{t('Import Fuel Cards')}</p>
          </div>
        }
        className="scrollable"
      >
        {!isCompletedImport && (<>
          <Formik
            enableReinitialize
            validationSchema={importFuelCardsValidationSchema}
            initialValues={initialImportValues}
            onSubmit={((values: ImportFuelCardValues, { setSubmitting }) => {
              setSubmitting(true);
              void handleFileUpload();
            })}
          >
            {(props: FormikProps<ImportFuelCardValues>) => (
              <Form>
                <div className="flex flex-col justify-center gap-y-6 mt-4">
                  <div className="flex items-center justify-center align-middle">
                    <div className="w-full">
                      <Input
                        name="fuelCardProvider"
                        type="select"
                        label={t('Provider')}
                        options={fuelCardProviders}
                        onChange={handleProviderChange}
                        defaultValue={accountToFcpData.length === 1 ? accountToFcpData[0].fuelCardProvider : null}
                      />
                    </div>
                    <div className="flex flex-col items-center gap-2 w-1/4">
                      <p className="text-xs">{t('Template')}</p>
                      <div style={iconButtonContainerStyle}>
                        {isUploading && (<div className="loading-overlay"><CircularProgress/></div>)}
                        {isDownloading && <CircularProgress style={progressStyle}/>}
                        <IconButton
                          icon="download"
                          onClick={() => {
                            if (props.values.fuelCardProvider) {
                              void handleDownloadClick();
                            } else {
                              openToast({
                                type: 'error',
                                label: t('No provider selected'),
                                autoClose: 2000,
                                theme: 'dark'
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center flex justify-left">
                    <div className="min-w-[80%]">
                      <Input
                        name="fuelCardImport"
                        type="file"
                        setState={handleFileChange}
                      />
                      {selectedFile && <div className="flex items-center gap-x-2">
                        <Button
                          style={{ marginTop: 5, padding: '0 8px' }}
                          outline
                          onClick={() => {
                            setSelectedFile(null);
                          }}
                        >
                          <Icon name="trash"/>
                        </Button>
                        <p className="text-xs">{selectedFile.name}</p>
                      </div>}
                    </div>
                    <div className="flex items-center gap-x-1 ml-5 mt-1">
                      <Switch
                      checked={allFuelCards}
                      onClick={(newValue: boolean) => {
                        setAllFuelCards(newValue);
                      }}
                      />
                    </div>
                    <div className="flex items-center ml-2 mb-0.5">
                      <Tooltip>
                        <label>
                          {t('Toggle OFF to download template with only unpaired vehicles')}
                        </label>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
                <div className="flex items-center justify-left mt-4">
                  <Button
                    type="submit"
                    className="!px-12"
                  >
                    {t('Submit')}
                  </Button>
                  <Button
                    onClick={onHide}
                    className="!px-12 ml-4"
                    outline
                  >
                    {t('Close')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>)}
        {isCompletedImport && (
          <div className="mt-4">
            <div className="text-success-2 mb-4">
              {t('Successfully imported')} {importResponseSuccessCount} {t('rows')}.
            </div>
            <div className="text-error-1">
              {Object.entries(importResponseErrors).map(([error, rows]) => (
                <p key={error}>
                  {`Rows: (${rows.join(', ')}): ${error}`}
                </p>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default withTranslation()(ImportFuelCards);
