import React, { useEffect, useState } from 'react';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';
import axios from 'axios';
import Modal from '../../../ui/shared/Modal';
import Button from '../../../ui/shared/Button';
import Icon from '../../../ui/shared/Icon';
import { DataGrid, GridToolbar, type GridColDef } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { openToast } from '../../../helpers/toast';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

interface Migration {
  id: number;
  batch: number;
  migration: string;
}

const Status: React.FC = () => {
  const [latestMigrations, setLatestMigrations] = useState<Migration[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);
  const onHide = (): void => {
    setIsShown(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/api/status`;
        const response = await axios.get(url);
        if (response.data.data) {
          setLatestMigrations(response.data.data);
          setSuccess(true);
        }
      } catch (error: any) {
        console.error(error);
        setSuccess(false);
        openToast({
          type: 'error',
          label: 'Error getting migrations',
          autoClose: 2000,
          theme: 'dark'
        });
      }
    })();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'batch',
      headerName: 'Batch',
      width: 60
    },
    {
      field: 'migration',
      headerName: 'File',
      width: 400
    }
  ];

  return (
    <>
      <div className="overflow-auto">
        <div className="min-w-[20rem] max-w-[20rem] over flex gap-x-10">
          <div className="flex flex-col gap-x-5 gap-y-2 text-6xl">
            <p className="flex items-center text-lg">Status:</p>
            {success ? <GppGoodIcon fontSize="inherit"/> : <GppBadIcon fontSize="inherit"/>}
          </div>
          <div className="flex flex-col gap-x-5 gap-y-2">
            <p className="flex items-center text-lg">Latest Migration:</p>
            <p className="text-sm ">{latestMigrations[0]?.migration}</p>
          </div>
        </div>
        <Button
          className="mb-4"
          onClick={() => {
            setIsShown(true);
          }}
        >
          <ReadMoreIcon/>
        </Button>
      </div>
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="gear"/>
            <p>Migrations</p>
          </div>
        )}
        show={isShown}
        onHide={onHide}
        maxWidth={'35rem'}
        maxHeight={'50rem'}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <DataGrid
            hideFooter={true}
            rows={latestMigrations}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableDensitySelector
            disableRowSelectionOnClick
            density="compact"
            slotProps={{
              toolbar: { // Disable Export Option
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            slots={{ toolbar: GridToolbar }}
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '15rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default Status;
