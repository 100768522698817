import React from 'react';
import Button from './shared/Button';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen -my-4 grid place-content-center place-items-center text-custom-gray-main">
      <p className="text-[6rem] leading-[10rem]">404</p>
      <div className="flex flex-col items-center mt-8">
        <p className="text-lg">Sorry, this page was not found. Please another page</p>
        <Button
          onClick={() => {
            navigate('/');
          }}
        >
          Reload Page
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
