/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';

// eslint-disable-next-line
const useOnClickOutside = (className: any, handler: any, isOpen: boolean): void => {
  useEffect(
    () => {
      const element = document.querySelector(className);

      const listener = (event: any): void => {
        if (element?.contains?.(event.target)) {
          return;
        }

        handler(event);
      };

      document?.body?.addEventListener('mousedown', listener);
      document?.body?.addEventListener('touchstart', listener);

      return () => {
        document?.body?.removeEventListener('mousedown', listener);
        document?.body?.removeEventListener('touchstart', listener);
      };
    },
    [className, handler]
  );
};

export default useOnClickOutside;
