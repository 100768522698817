import React, { useState, useEffect } from 'react';
import IconButton from '../../IconButton';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { Formik } from 'formik';
import Input from '../../Formik/Input';
import Button from '../../Button';
import axios from 'axios';
import '../../../../index.css';
import { t } from 'i18next';
import { appState } from '../../../../index';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';

interface MostExceptionsSettingsModalProps {
  settingsUpdated: boolean;
  setSettingsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}
interface UserSettings {
  bad_transactions_threshold: number;
  default_days_displayed: any;
  user_id: string;
  id: string;
}
const MostExceptionsSettingsModal: React.FC<MostExceptionsSettingsModalProps> = ({ settingsUpdated, setSettingsUpdated }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [accountSettings, setAccountSettings] = useState({});
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const mostExceptionsSettingsSchema = Yup.object().shape({
    bad_transactions_threshold: Yup.number().required('Required')
      .positive('Please enter a positive number')
      .typeError('Please enter a number'),
    custom_days_displayed: Yup.number()
      .positive('Please enter a positive number')
      .typeError('Please enter a number')
  });

  const defaultDays = [1, 7, 14, 30, 365];
  const fetchAlertSettings = async (): Promise<void> => {
    try {
      const userSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${appState.userId.value}(eq)`);
      if (!userSettingsResponse.data.data.length) {
        return;
      }
      setUserSettings(userSettingsResponse.data.data[0]);
      const accountSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/account-settings?filter=account_id:${appState.accountId.value}(eq)`);
      if (!accountSettingsResponse.data.data.length) {
        return;
      }
      setAccountSettings(accountSettingsResponse.data.data[0]);
      if (!defaultDays.includes(userSettingsResponse.data.data[0]?.default_days_displayed)) {
        setShowCustomInput(true);
      }
    } catch (error) {
      console.error('Error fetching user settings: ', error);
    }
  };
  const updateUserSettings = async (settingsSubmitted: any): Promise<void> => {
    try {
      setLoading(true);
      if (!settingsSubmitted) {
        setIsShown(false);
        return;
      }
      if (!userSettings || !accountSettings) {
        setIsShown(false);
        return;
      }
      settingsSubmitted.userId = userSettings.user_id;
      settingsSubmitted.userSettingsId = userSettings.id;
      settingsSubmitted.accountId = (accountSettings as any).account_id;
      settingsSubmitted.accountSettingsId = (accountSettings as any).id;
      settingsSubmitted.default_days_displayed = (settingsSubmitted.default_days_displayed ? parseInt(settingsSubmitted.default_days_displayed) : userSettings.default_days_displayed);
      settingsSubmitted.default_days_displayed = settingsSubmitted.custom_days_displayed ? parseInt(settingsSubmitted.custom_days_displayed) : settingsSubmitted.default_days_displayed;
      settingsSubmitted.bad_transactions_threshold = (settingsSubmitted.bad_transactions_threshold ? settingsSubmitted.bad_transactions_threshold : userSettings.bad_transactions_threshold);
      settingsSubmitted._method = 'PUT';
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user-settings/${settingsSubmitted.userSettingsId}`, settingsSubmitted);
    } catch (error) {
      console.log('Error updating user settings: ', error);
    } finally {
      setLoading(false);
    }
    setSettingsUpdated(true);
  };

  const isCustom = (newValue: any): void => {
    if (newValue === 'custom') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
    }
  };

  useEffect(() => {
    void fetchAlertSettings();
  }, []);
  return (
    <>
      <IconButton
        icon="gear"
        onClick={() => {
          setIsShown(true);
        }}
      />
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="gear" />
            <p>{t('Settings')}</p>
          </div>
        )}
        show={isShown}
        onHide={() => {
          setIsShown(false);
        }}
      >
        <Formik
          enableReinitialize
          validationSchema={mostExceptionsSettingsSchema}
          initialValues={{
            bad_transactions_threshold: userSettings?.bad_transactions_threshold ?? 1,
            default_days_displayed: userSettings?.default_days_displayed ?? 14
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await updateUserSettings(values);
            setSubmitting(false);
            setIsShown(false);
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                {loading && (
                  <div
                    className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
                  >
                    <CircularProgress/>
                  </div>
                )}
                <div className="mb-3 flex flex-col gap-y-3">
                  <Input
                    name="bad_transactions_threshold"
                    type="number"
                    label={t('Transaction Discrepancy Threshold')}
                    defaultValue={props.values.bad_transactions_threshold}
                  />
                  <Input
                    name="default_days_displayed"
                    type="select"
                    label={t('Default Period')}
                    options={[
                      { key: `${t('Yesterday')}`, value: '1' },
                      { key: `${t('Last Week')}`, value: '7' },
                      { key: `${t('Last Two Weeks')}`, value: '14' },
                      { key: `${t('Last Month')}`, value: '30' },
                      { key: `${t('Last Year')}`, value: '365' },
                      { key: `${t('Custom')}...`, value: 'custom' }
                    ]}
                    defaultValue={showCustomInput ? 'custom' : userSettings?.default_days_displayed ? (userSettings?.default_days_displayed).toString() : ''}
                    onChange={isCustom}
                  />
                  { showCustomInput &&
                  <Input
                    name="custom_days_displayed"
                    type="number"
                    label="Number of Days"
                    defaultValue={userSettings?.default_days_displayed ? userSettings.default_days_displayed : 14}
                  /> }
                </div>
                <Button
                  disabled={props.isSubmitting}
                  className="!px-12"
                >
                  {t('Submit')}
                </Button>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default MostExceptionsSettingsModal;
