import { useEffect } from 'react';
import { favicon } from '../helpers/CustomerAssets';

const useFavicon = (): void => {
  useEffect(() => {
    // Cast the element to HTMLLinkElement
    const link: HTMLLinkElement = (document.querySelector("link[rel*='icon']") as HTMLLinkElement) || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = favicon.value;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [favicon.value]);
};

export default useFavicon;
