import React, { useState } from 'react';
import * as Yup from 'yup';
import Modal from '../Modal';
import Icon from '../Icon';
import { Formik } from 'formik';
import Button from '../Button';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../index.css';
import axios, { AxiosError } from 'axios';
import { t } from 'i18next';
import { openToast } from '../../../helpers/toast';
import { appState, refreshLogin } from '../../../index';
import { type User, type GeotabUser } from '../../../routes/Authorized/Users/UserTypes';
import SearchableDropdown from '../SearchableDropdown';

const GeotabUserSchema = Yup.object().shape({
  geotabUsername: Yup.string().required('Required')
});

interface Props {
  geotabUser: GeotabUser | null;
  updateUsers: (updatedUserData: User, method: 'update' | 'remove' | 'create' | 'geotabUser') => void;
  geotabUsers: any;
  show: boolean;
  onHide: () => void;
  loadingGeotabUsers: boolean;
}

const GeotabUserEditorModal: React.FC<Props> = ({
  show,
  geotabUser,
  updateUsers,
  geotabUsers,
  onHide,
  loadingGeotabUsers
}) => {
  const [showSetError, setShowSetError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const setGeotabUser = async (formikValues: any): Promise<void> => {
    if (geotabUser?.geotabUsername === formikValues.geotabUsername) {
      onHide();
      return;
    }

    // Disallow replacing the currently signed in Geotab user without reassigning first, cannot have the currently
    // signed in Geotab user unpaired, only for when not logged in as a child
    if (!appState.loggedInAsChild.value && appState.activeGeotabUserId.value && appState.activeGeotabUserId.value === `${geotabUser?.id}`) {
      openToast({
        type: 'error',
        label: 'You are currently logged into this Geotab user. If you need to remove this Geotab user from the selected user, please reassign them to another user',
        autoClose: 15000,
        theme: 'dark'
      });
      return;
    }
    try {
      if (!geotabUser || !formikValues) {
        return;
      }
      setShowSetError(false);
      const userId: number | null = geotabUser?.userId;
      // Passes the geotabUsername if adding one or changing one, does not send if no change in Geotab user
      const params: { database: any, _method: string, geotab_username: string, } = {
        _method: 'PUT',
        database: formikValues.hasGeotabUser && geotabUser?.geotabUsername === formikValues.geotabUsername ? null : formikValues.database,
        geotab_username: formikValues.hasGeotabUser && geotabUser?.geotabUsername === formikValues.geotabUsername ? null : formikValues.geotabUsername
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, params);
      if (!response) {
        setShowSetError(true);
        throw Error('Response is empty');
      }
      if (response.status !== 200) {
        setShowSetError(true);
        return;
      }

      // If the selected user's Geotab user was updated to the currently signed in Geotab user, re-log to sign into that new user
      // For customers and when super admins who are not signed in as a child user, you will be relogged.
      // If a super admin and logged in as child, re-log is not necessary since your logged into the child user which
      // always uses the accounts service geotab user
      if (!appState.loggedInAsChild.value && formikValues.geotabUsername === appState.activeGeotabUserName.value) {
        openToast({
          type: 'success',
          label: 'You have re-assigned your Geotab user. Logging into the newly assigned user.',
          autoClose: 3000,
          theme: 'dark'
        });
        setTimeout(() => {
          refreshLogin();
        }, 3000);
      }
      const updatedUser: User = {
        accountId: response.data.data.account_id,
        id: userId,
        name: response.data.data.name,
        email: response.data.data.email,
        login_access: response.data.data.login_access,
        geotabUserId: response.data.data.geotab_user_id,
        geotabUsername: formikValues.geotabUsername,
        role: response.data.data.role
      };
      updateUsers(updatedUser, 'geotabUser');
      onHide();
    } catch (error: AxiosError | any) {
      console.error('Error updating Geotab user: ', error);
      setShowSetError(true);
      let message = 'Error updating Geotab user';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
    }
  };
  const removeGeotabUser = async (): Promise<void> => {
    try {
      if (!geotabUser) {
        return;
      }
      setLoading(true);
      const loggedInGeotabUser = JSON.parse(localStorage.geotabAPI_credentials).userName;
      if (loggedInGeotabUser === geotabUser?.geotabUsername) {
        openToast({
          type: 'error',
          label: 'This Geotab user is currently logged in. If you need to remove this Geotab user from the current user, please reassign to another user',
          autoClose: 15000,
          theme: 'dark'
        });
        return;
      }
      const params: { geotab_user_id: null, } = {
        geotab_user_id: null
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${geotabUser?.userId}`, params);
      updateUsers(response.data?.data, 'geotabUser');
    } catch (error) {
      console.log('Error removing geotab user: ', error);
    } finally {
      setLoading(false);
      onHide();
    }
  };
  return (
        <Modal
            header={(
                <div className="flex items-center gap-x-2">
                    <Icon name={!geotabUser?.id ? 'plus' : 'edit'}/>
                    <p>{!geotabUser?.id ? t('Add') : t('Edit')} {t('MyGeotab User')}</p>
                </div>
            )}
            show={show}
            onHide={onHide}
        >
            <Formik
                enableReinitialize
                initialValues={{
                  ...geotabUser
                }}
                validationSchema={GeotabUserSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await setGeotabUser(values);
                  setSubmitting(false);
                }}
            >
                {(props: any) => {
                  return (
                        <form
                            onSubmit={props.handleSubmit}
                            className="mt-4"
                        >
                            {(props.isSubmitting || loading) && (
                                <div className="loading-overlay">
                                    <CircularProgress/>
                                </div>
                            )}
                            {!loadingGeotabUsers
                              ? <div className="flex flex-col gap-y-6">
                                    <SearchableDropdown
                                      name="geotabUsername"
                                      options={geotabUsers.map((user: string) => ({
                                        value: user,
                                        label: user
                                      }))}
                                      selectedVal={props.values.geotabUsername ?? ''}
                                      handleChange={(username: string) => {
                                        props.setFieldValue('geotabUsername', username);
                                      }}
                                    />
                                </div>
                              : <div className="flex justify-center items-center">
                                    <CircularProgress/>
                                </div>
                            }
                            {showSetError && (
                                <p
                                    className="text-error-1 text-sm"
                                    style={{ marginTop: 15 }}
                                >
                                    {t('Could not assign that geotab user.')}
                                </p>
                            )}
                            <div className="flex items-center gap-x-3">
                                <Button
                                    disabled={props.isSubmitting}
                                    className="w-1/2"
                                >
                                    {t('Submit')}
                                </Button>
                                {!!geotabUser?.id && `${geotabUser?.id}` !== (appState.activeGeotabUserId?.value
                                  ? appState.activeGeotabUserId.value
                                  : '') && (
                                    <Button
                                        type="button"
                                        disabled={props.isSubmitting}
                                        className="w-1/2"
                                        outline
                                        variant="light"
                                        onClick={(): void => {
                                          void removeGeotabUser();
                                        }}
                                    >
                                        {t('Remove')}
                                    </Button>
                                )}
                            </div>
                        </form>
                  );
                }}
            </Formik>
        </Modal>
  );
};

export default GeotabUserEditorModal;
