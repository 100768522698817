import React, { useEffect, useRef, useState } from 'react';
import Icon from './Icon';
import { animated, type SpringValue, useSpringValue } from '@react-spring/web';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

interface SearchButtonProps {
  value?: string;
  isDefaultOpen?: boolean;
  onChange: (val: string) => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({ value, onChange, isDefaultOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen ?? false);
  const width: SpringValue<number> = useSpringValue(isOpen ? 180 : 36);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      void width.start(180);
      if (!isDefaultOpen) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    } else {
      void width.start(36);
    }
  }, [isOpen]);

  return (
    <animated.div
      className="flex bg-dark-2 rounded-xl h-[36px] text-custom-blue-normal hover:text-custom-blue-hover overflow-hidden"
      style={{ width }}
    >
      <button
        type="button"
        className="h-full min-w-[36px]"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon name="magnifying-glass" />
      </button>
      <input
        ref={inputRef}
        type="text"
        className="w-full bg-transparent outline-none border-none text-white/60 text-xs"
        placeholder={t('Search...')}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {!isDefaultOpen &&
      <button
        type="button"
        className="h-full min-w-[36px]"
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <Icon name="times" />
      </button>}
    </animated.div>
  );
};

export default withTranslation()(SearchButton);
