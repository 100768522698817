import React, { Suspense, useEffect } from 'react';
import { effect } from '@preact/signals-core';
import { useTranslation } from 'react-i18next';
import Logo from '../../Logo';
import { AUTHORIZED_ROUTES } from '../../../enums/ROUTES';
import { NavLink } from 'react-router-dom';
import Icon from '../Icon';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import { appState } from '../../../index';
import SidebarFooter from './SidebarFooter';

interface IMenuItem {
  link: string;
  label: string;
  icon: IconName;
}

const locales: any = {
  en: { title: 'English' },
  es: { title: 'Spanish' }
};

const Sidebar: React.FC = () => {
  const { t, i18n } = useTranslation();

  const commonMenuItems: IMenuItem[] = [
    {
      link: AUTHORIZED_ROUTES.TRANSACTIONS,
      label: 'Transactions',
      icon: 'arrow-right-arrow-left'
    },
    {
      link: AUTHORIZED_ROUTES.MOST_EXCEPTIONS,
      label: 'Most Exceptions',
      icon: 'users-line'
    },
    {
      link: AUTHORIZED_ROUTES.FUEL_CARDS,
      label: 'Fuel Cards',
      icon: 'credit-card'
    },
    {
      link: AUTHORIZED_ROUTES.FILLING_INFO,
      label: 'Fueling Efficiency',
      icon: 'clock'
    },
    {
      link: AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS,
      label: 'Dismissed',
      icon: 'box-archive'
    },
    {
      link: AUTHORIZED_ROUTES.REPORTS,
      label: 'Reports',
      icon: 'flag'
    },
    {
      link: AUTHORIZED_ROUTES.USERS,
      label: 'Users',
      icon: 'users'
    }
  ];

  const resellerItems: IMenuItem[] = [
    {
      link: AUTHORIZED_ROUTES.RESELLERS,
      label: 'Reseller Dashboard',
      icon: 'users'
    }
  ];

  const superAdminItems: IMenuItem[] = [
    {
      link: AUTHORIZED_ROUTES.SUPERADMIN,
      label: 'SA Dashboard',
      icon: 'table-columns'
    },
    {
      link: AUTHORIZED_ROUTES.SYSTEM_ADMIN,
      label: 'System Admin',
      icon: 'user-lock'
    }
  ];

  const MENU_ITEMS: IMenuItem[] = [...commonMenuItems];

  const parentDataString: string | null = localStorage.getItem('parentData');
  const parentDataObject = parentDataString ? JSON.parse(parentDataString) : null;

  if (parentDataObject?.accountLevel === 'reseller' || appState.accountLevel.value === 'reseller') {
    MENU_ITEMS.push(...resellerItems);
  }

  if (parentDataObject?.accountLevel === 'superadmin' || appState.accountLevel.value === 'superadmin') {
    MENU_ITEMS.push(...superAdminItems);
  }

  useEffect(() => {
    effect(() => {
      if (appState.language.value) {
        void i18n.changeLanguage(appState.language.value);
      }
    });
  }, []);

  return (
    <Suspense fallback="loading...">
      <div
        className="w-[200px] min-w-[200px] sticky top-0 h-screen bg-dark-3 rounded-tr-xl rounded-br-xl flex flex-col py-6"
      >
        <div className="px-6">
          <Logo className="mb-8"/>
          <br></br>
        </div>
        <ul className="flex-1 flex flex-col gap-y-3 px-4">
          {MENU_ITEMS.map((item: IMenuItem) => {
            return (
              <li
                key={item.link}
                className="flex-grow-1"
              >
                <NavLink
                  to={item.link}
                  className={(navData) => {
                    let styles = 'flex items-center rounded-full py-1.5 px-1';

                    if (navData.isActive) {
                      styles += ' text-custom-blue-normal bg-custom-blue-1';
                    } else {
                      styles += ' text-custom-gray-normal';
                    }

                    return styles;
                  }}
                >
                  <div className="w-[2rem] grid place-content-center pr-3">
                    <Icon
                      name={item.icon}
                      type="fad"
                    />
                  </div>
                  {t(item.label)}
                </NavLink>
              </li>
            );
          })}
        </ul>
          {appState.accountLevel.value === 'superadmin'
            ? <div className="flex mx-4">
                <SidebarFooter />
              </div>
            : null}
      </div>
    </Suspense>
  );
};

export default Sidebar;
