import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Input from '../../Formik/Input';
import Button from '../../Button';
import IconButton from '../../IconButton';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { fuelCardProviderOptions } from '../../../../helpers/fcp';

const CreateAccountSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  providerCode: Yup.string().required('Required'),
  providerUniqueId: Yup.string().required('Required')
});

interface Props {
  createAccount: any;
}

const CreateAccountModal: React.FC<Props> = ({ createAccount }) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const onHide = (): void => {
    setIsShown(false);
  };

  return (
    <>
      <IconButton
        icon="plus"
        onClick={() => {
          setIsShown(true);
        }}
      />
      {isShown && <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="plus" />
            <p>{t('Add Account')}</p>
          </div>
        )}
        show={isShown}
        onHide={onHide}
      >
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            providerCode: '',
            providerUniqueId: ''
          }}
          validationSchema={CreateAccountSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              createAccount(values);
              setSubmitting(false);
              onHide();
            }, 400);
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <div className="flex flex-col gap-y-6">
                  <Input
                    name="name"
                    type="default"
                    label={t('Name')}
                  />
                  <Input
                    name="providerCode"
                    type="select"
                    options={fuelCardProviderOptions}
                    label={t('Provider')}
                    placeholder={`${t('Select')}...`}
                  />
                  <Input
                    name="providerUniqueId"
                    type="default"
                    label={t('Provider Unique Id')}
                  />
                </div>
                <Button
                  disabled={props.isSubmitting}
                  className="!px-12"
                >
                  {t('Submit')}
                </Button>
              </form>
            );
          }}
        </Formik>
      </Modal>}
    </>
  );
};

export default withTranslation()(CreateAccountModal);
