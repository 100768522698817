import React, { useEffect, useState } from 'react';
import Table from '../../../ui/shared/Table';
import Icon from '../../../ui/shared/Icon';
import UserEditorModal from '../../../ui/shared/Modals/user/UserEditor.modal';
import IconButton from '../../../ui/shared/IconButton';
import GeotabUserEditorModal from '../../../ui/shared/Modals/GeotabUserEditor.modal';
import UserSettingsEditorModal from '../../../ui/shared/Modals/user/UserSettingsEditor.modal';
import axios from 'axios';
import { defaultUser, type GeotabUser, isAdmin, type User } from './UserTypes';
import { appState, useSignal } from '../../../index';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { updateUsers } from '../../../helpers/users';
import { openToast } from '../../../helpers/toast';

const Users: React.FC = () => {
  const updatedAccountId = useSignal(appState.accountId);
  const [account, setAccount] = useState<any | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>(defaultUser);
  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGeotabUsers, setLoadingGeotabUsers] = useState<boolean>(false);
  const [geotabUser, setGeotabUser] = useState<GeotabUser | null>(null);
  const [geotabUsers, setGeotabUsers] = useState<GeotabUser[]>([]);
  const [isGeotabUserModalOpen, setIsGeotabUserModalOpen] = useState<boolean>(false);
  const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState<boolean>(false);
  const [database, setDatabase] = useState<string | null>(null);
  const onAddUser = (): void => {
    setIsUserModalOpen(true);
    setUser({
      accountId: Number(appState.accountId.value),
      email: null,
      login_access: true,
      geotabUsername: null,
      geotabUserId: null,
      id: null,
      name: null,
      role: 'user'
    });
  };

  const transformData = (Users: any): User[] => {
    return Users.map((user: any): User => {
      return {
        accountId: user.account_id,
        geotabUsername: user?.geotab_user?.username,
        geotabUserId: user?.geotab_user?.id,
        id: user.id,
        name: user.name,
        email: user.email,
        login_access: user.login_access,
        role: user.role
      };
    });
  };
  const fetchAccount = async (): Promise<void> => {
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${appState.accountId.value}`;
      const response = await axios.get(url);
      setAccount(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUsers = async (): Promise<void> => {
    try {
      setLoading(true);
      const url: string = `${process.env.REACT_APP_API_URL}/api/users-minimized/${appState.accountId.value}`;
      const response = await axios.get(url);
      setUsers(transformData(response.data.data));
      for (let i: number = 0; i < response.data.data.length; i++) {
        if (response.data.data[i].geotab_user) {
          setDatabase(response.data.data[i].geotab_user.database);
        }
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGeotabUsers = async (): Promise<void> => {
    setLoadingGeotabUsers(true);
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/geotab-users`;
      const params: { account_id: string | null, user_id: string | null } = {
        user_id: appState.userId.value,
        account_id: appState.accountId.value
      };
      const geotabUsersResponse = await axios.post(url, params);
      if (geotabUsersResponse) {
        setGeotabUsers(geotabUsersResponse.data.mygeotabUsers);
      } else {
        setGeotabUsers([]);
      }
      // also add vehicle groups
    } catch (error) {
      console.log('Error fetching geotab users: ', error);
    }
    setLoadingGeotabUsers(false);
  };

  useEffect((): void => {
    void fetchAccount();
    void fetchUsers();
    void fetchGeotabUsers();
  }, [updatedAccountId]);

  return (
        <>
            {loading && (
                <div
                    className="p-4 rounded-xl absolute w-full h-full flex justify-center items-center loading bg-opacity-50 bg-black"
                >
                    <CircularProgress/>
                </div>
            )}
            <div className="bg-dark-3 p-4 rounded-xl min-h-[60rem]">
                <Table
                    headerComponent={(
                        <div className="flex items-center justify-between mb-3">
                            <div className="flex items-center gap-x-2 text-custom-gray-light">
                                <Icon name="users"/>
                                <span>{t('Users')}</span>
                            </div>
                          {isAdmin(appState.userRole.value) &&
                            <div className="flex items-center gap-x-3">
                                <IconButton
                                    icon="plus"
                                    onClick={onAddUser}
                                />
                            </div>}
                        </div>
                    )}
                    headers={[
                      {
                        name: '',
                        width: 50,
                        key: 'actions'
                      },
                      {
                        name: t('Name'),
                        width: 200,
                        key: 'name'
                      },
                      {
                        name: t('Role'),
                        width: 200,
                        key: 'role'
                      },
                      {
                        name: t('Email Address'),
                        width: 300,
                        key: 'email'
                      },
                      {
                        name: t('Geotab User'),
                        width: 300,
                        key: 'geotabUsername'
                      }
                    ]}
                    data={[...users].sort((userA: User, userB: User): number => {
                      const keyA: string = userA.name ? userA.name.toLowerCase() : '';
                      const keyB: string = userB.name ? userB.name.toLowerCase() : '';
                      if (keyA < keyB) return -1;
                      if (keyA > keyB) return 1;
                      return 0;
                    })}

                    renderBodyItem={(dataItem: any) => {
                      return (
                            <div
                                className="flex items-center text-sm bg-dark-2 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                                key={dataItem?.id}
                            >
                                <div
                                    style={{ width: 50, maxWidth: 50 }}
                                    className="flex items-center h-full gap-x-3"
                                >
                                    <button
                                        type="button"
                                        className="text-custom-blue-normal hover:text-custom-blue-hover"
                                        onClick={() => {
                                          setIsUserSettingsModalOpen(true);
                                          setUser(dataItem);
                                        }}
                                    >
                                        <Icon name="gear"/>
                                    </button>
                                    <button
                                        type="button"
                                        className="text-custom-blue-normal hover:text-custom-blue-hover"
                                        onClick={() => {
                                          setIsUserModalOpen(true);
                                          setUser(dataItem);
                                        }}
                                    >
                                        <Icon name="edit"/>
                                    </button>
                                </div>
                                <div style={{ width: 200, maxWidth: 200 }}>
                                    {dataItem.name}
                                </div>
                                <div style={{ width: 200, maxWidth: 200 }}>
                                    {`${dataItem.role.slice(0, 1).toUpperCase()}` + `${dataItem.role.slice(1).replace('_', ' ')}`}
                                </div>
                                <div style={{ width: 300, maxWidth: 300 }}>
                                    {dataItem.email}
                                </div>
                                <div style={{ width: 300, maxWidth: 300 }}>
                                    {dataItem.geotabUsername && (
                                        <button
                                            onClick={(): void => {
                                              setIsGeotabUserModalOpen(() => {
                                                if (appState.activeGeotabUserId.value && appState.activeGeotabUserId.value === `${dataItem.geotabUserId}`) {
                                                  openToast({
                                                    type: 'error',
                                                    label: 'You must reassign your current Geotab user to modify this user.',
                                                    autoClose: 15000,
                                                    theme: 'dark'
                                                  });
                                                  return false;
                                                }
                                                return true;
                                              });
                                              setGeotabUser({
                                                accountId: Number(appState.accountId.value),
                                                database,
                                                geotabUsername: dataItem?.geotabUsername,
                                                hasGeotabUser: true,
                                                id: dataItem.geotabUserId,
                                                userId: dataItem.id
                                              });
                                            }}
                                            className="text-custom-blue-normal"
                                        >
                                            {dataItem?.geotabUsername}
                                        </button>
                                    )}
                                    {!dataItem.geotabUsername && (
                                        <button
                                            type="button"
                                            className="transition border border-custom-blue-normal text-custom-blue-normal hover:bg-custom-blue-normal hover:text-white rounded-full text-xs px-4 py-1"
                                            onClick={(): void => {
                                              setIsGeotabUserModalOpen(true);
                                              setGeotabUser({
                                                accountId: Number(appState.accountId.value),
                                                database,
                                                geotabUsername: null,
                                                hasGeotabUser: false,
                                                id: null,
                                                userId: dataItem.id
                                              });
                                            }}
                                        >
                                            {t('Add Geotab User')}
                                        </button>
                                    )}
                                </div>
                            </div>
                      );
                    }}
                />
                {isUserModalOpen && account && user &&
                    <UserEditorModal
                        account={account}
                        user={user}
                        updateUsers={(user: User, method: 'update' | 'remove' | 'create') => {
                          updateUsers(user, method, setUsers);
                        }}
                        show={true}
                        onHide={() => {
                          setIsUserModalOpen(false);
                        }}
                    />
                }
                {isUserSettingsModalOpen && user &&
                    <UserSettingsEditorModal
                        user={user}
                        show={true}
                        onHide={() => {
                          setIsUserSettingsModalOpen(false);
                        }}
                    />
                }
                {isGeotabUserModalOpen && geotabUser && geotabUsers &&
                    <GeotabUserEditorModal
                        geotabUser={geotabUser}
                        geotabUsers={geotabUsers}
                        updateUsers={(user: User, method: 'update' | 'remove' | 'create' | 'geotabUser') => {
                          updateUsers(user, method, setUsers);
                        }}
                        show={true}
                        loadingGeotabUsers={loadingGeotabUsers}
                        onHide={() => {
                          setIsGeotabUserModalOpen(false);
                        }}
                    />}
            </div>
        </>
  );
};

export default withTranslation()(Users);
