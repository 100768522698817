import React, { useEffect, useState } from 'react';
import Modal from '../../Modal';
import IconButton from '../../IconButton';
import Button from '../../Button';
import Icon from '../../Icon';
import Input from '../../Formik/Input';
import axios from 'axios';
import { type UserSettings } from '../../../../types/user.d';
import { CircularProgress } from '@mui/material';
import { Formik, type FormikProps } from 'formik';
import { t } from 'i18next';
import { openToast } from '../../../../helpers/toast';
import { defaultDays } from '../user/UserSettingsEditor.modal';
import * as Yup from 'yup';

interface Props {
  fetchData: () => void;
  userSettings: UserSettings | null;
}

export default function FuelingEfficiencySettingsModal ({ userSettings, fetchData }: Props): React.ReactElement {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showCustomInput, setShowCustomInput] = useState<boolean>(false);
  const [defaultFuelingEfficiencyPeriod, setDefaultFuelingEfficiencyPeriod] = useState<string>('');

  const fuelingEfficiencySettingsSchema = Yup.object().shape({
    custom_fueling_efficiency_days: Yup.number().required('Required')
      .positive('Please enter a positive number')
      .typeError('Please enter a number')
  });

  useEffect(() => {
    try {
      if (userSettings?.default_fueling_efficiency_days) {
        setDefaultFuelingEfficiencyPeriod(userSettings.default_fueling_efficiency_days);
        if (!defaultDays.includes(parseInt(userSettings.default_fueling_efficiency_days))) {
          setShowCustomInput(true);
        } else {
          setShowCustomInput(false);
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  }, [userSettings]);

  const isCustom = (newValue: string): void => {
    setDefaultFuelingEfficiencyPeriod(newValue);
    if (newValue === 'custom') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
    }
  };

  const onHide = (): void => {
    setIsShown(false);
  };

  return (
    <>
      <IconButton
        icon="gear"
        onClick={() => {
          setIsShown(true);
        }}
      />
      <Modal
        onHide={onHide}
        show={isShown}
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="gear" />
            <p>{t('User Settings: Fueling Efficiency')}</p>
          </div>
        )}
      >
        {(loading || !userSettings) && (
          <div className="loading-overlay">
            <CircularProgress />
          </div>
        )}
        {userSettings && <Formik
          validationSchema={fuelingEfficiencySettingsSchema}
          initialValues={{
            default_fueling_efficiency_days: userSettings.default_fueling_efficiency_days,
            custom_fueling_efficiency_days: userSettings.default_fueling_efficiency_days
          }}
          onSubmit={async (values) => {
            if (!values.default_fueling_efficiency_days || !values.custom_fueling_efficiency_days) {
              return;
            }
            try {
              const updatedSettings = {
                default_fueling_efficiency_days: parseInt(showCustomInput
                  ? values.custom_fueling_efficiency_days
                  : values.default_fueling_efficiency_days)
              };
              await axios.put(
                `${process.env.REACT_APP_API_URL}/api/user-settings/${userSettings.id}`, updatedSettings
              );
              openToast({
                type: 'success',
                label: t('Updated fueling efficiency period'),
                autoClose: 2000,
                theme: 'dark'
              });
            } catch (error: any) {
              console.error('Error updating user settings: ', error);
              openToast({
                type: 'error',
                label: t('Error updating user settings'),
                autoClose: 2000,
                theme: 'dark'
              });
            } finally {
              fetchData();
              setLoading(false);
              onHide();
            }
          }}
        >
          {(props: FormikProps<any>) => (
            <form onSubmit={props.handleSubmit}>
              <div className="flex flex-col gap-y-2">
                <div className="my-3 flex flex-col gap-y-3">
                  <Input
                    name="default_fueling_efficiency_days"
                    type="select"
                    label={t('Default Period')}
                    options={[
                      { key: t('Yesterday'), value: '1' },
                      { key: t('Last Week'), value: '7' },
                      { key: t('Last Two Weeks'), value: '14' },
                      { key: t('Last Month'), value: '30' },
                      { key: t('Last Year'), value: '365' },
                      { key: t('Custom...'), value: 'custom' }
                    ]}
                    onChange={(newValue) => {
                      isCustom(newValue);
                    }}
                    defaultValue={showCustomInput ? 'custom' : defaultFuelingEfficiencyPeriod ? (defaultFuelingEfficiencyPeriod).toString() : ''}
                  />
                  {showCustomInput &&
                    <Input
                      name="custom_fueling_efficiency_days"
                      type="number"
                      label="Number of Days"
                      defaultValue={userSettings?.default_fueling_efficiency_days ? userSettings?.default_fueling_efficiency_days : ''}
                    />}
                </div>
              </div>
              <Button
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>}
      </Modal>
    </>
  );
};
