import { toast } from 'react-toastify';

const createToastProps = (props: any): any => {
  return {
    position: props.position,
    autoClose: props.autoClose,
    hideProgressBar: props.hideProgressBar,
    closeOnClick: props.closeOnClick,
    pauseOnHover: props.pauseOnHover,
    draggable: props.draggable,
    progress: props.progress,
    theme: props.theme
  };
};

export const openToast = ({
  type = 'info',
  label = '',
  position = 'top-right',
  autoClose = 5000,
  hideProgressBar = false,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = false,
  progress = undefined,
  theme = 'light'
}): void => {
  const props = createToastProps({
    label,
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    progress,
    theme
  });

  if (type === 'info') {
    toast.info(label, props);
    return;
  }

  if (type === 'success') {
    toast.success(label, props);
    return;
  }

  if (type === 'warning') {
    toast.warn(label, props);
    return;
  }

  if (type === 'error') {
    toast.error(label, props);
    return;
  }

  toast(label, props);
};
