import React, { useRef, useState } from 'react';
import RCDropdown from 'rc-dropdown';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
  children: React.ReactElement;
  handle?: any;
  menu?: any;
}

const Dropdown: React.FC<Props> = ({ children, handle, menu }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleOpen = (): void => {
    setIsOpen(!isOpen);
  };

  useOnClickOutside(
    '.rc-dropdown__open',
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  return (
    <RCDropdown
      ref={containerRef}
      visible={isOpen}
      trigger={['click']}
      overlay={children}
      animation="slide-up"
      overlayClassName={isOpen ? 'rc-dropdown__open' : ''}
    >
      <>
        {menu}
        {handle(toggleOpen)}
      </>
    </RCDropdown>
  );
};

export default Dropdown;
