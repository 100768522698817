import React, { useContext, useEffect, useRef } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { CircularProgress, CssBaseline } from '@mui/material';
import '../../../../index.css';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useTransactions from '../../../../hooks/useTransactions';
import { t } from 'i18next';
import { columns } from '../../TransactionColumns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TransactionsContext } from '../../../../contexts/transactionsContext';

interface Props {
  transactionIds?: any;
  onHide: () => void;
  show: boolean;
}

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const FillingInfoDetailsModal: React.FC<Props> = ({
  transactionIds,
  onHide,
  show
}) => {
  const gridRef = useRef<HTMLDivElement | null>(null);
  const apiUrl = 'get-transactions-with-ids';
  const {
    loading,
    transformedData,
    rowCountState,
    headerField,
    paginationModel,
    setPaginationModel,
    reloadTransactions,
    removeDismissedTransaction
  } = useTransactions({ page: 0, pageSize: 50 }, apiUrl, transactionIds);
  const transactionColumns = columns(headerField, removeDismissedTransaction, loading);
  const { transactions, setTransactions } = useContext(TransactionsContext);

  useEffect(() => {
    setTransactions(transformedData);
  }, [transformedData]);

  return (
    <>

      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="users-line"/>
            <p>{t('Filling Details')}</p>
          </div>
        )}
        maxWidth="80rem"
        show={show}
        onHide={onHide}
      >
        {loading && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 2
          }}
          >
            <CircularProgress/>
          </div>
        )}
        <div style={{ marginTop: '10px' }}>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div
              ref={gridRef}
              style={{ position: 'relative', height: '40rem' }}
            >
              <DataGrid
                disableColumnSelector={true}
                disableDensitySelector={true}
                localeText={{
                  noRowsLabel: t('No rows'),
                  toolbarColumns: t('Columns'),
                  toolbarColumnsLabel: t('Columns'),
                  toolbarDensity: t('Density'),
                  toolbarDensityLabel: t('Density'),
                  toolbarDensityCompact: t('Compact'),
                  toolbarDensityStandard: t('Standard'),
                  toolbarDensityComfortable: t('Comfortable'),
                  toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                  MuiTablePagination: {
                    labelRowsPerPage: t('Rows per Page'),
                    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                  }
                }}
                pagination
                rowCount={rowCountState}
                rows={transactions}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                columns={transactionColumns}
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                density="compact"
                slotProps={{
                  toolbar: { // Disable Export Option
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                slots={{ toolbar: GridToolbar }}
                className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                sx={{
                  height: '100%',
                  '& .MuiDataGrid-row': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    marginTop: '4px',
                    borderBottom: 'none'
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell': {
                    border: 'none'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    borderBottom: 'none',
                    marginBottom: '10px'
                  },
                  border: 0
                }}

              />
            </div>
          </ThemeProvider>
        </div>
      </Modal>
    </>
  );
};

export default FillingInfoDetailsModal;
