import { useField } from 'formik';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import React, { useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import Icon from '../Icon';
import Switch from '../Switch';
import { useRef } from 'react';
import Button from '../Button';
import { t } from 'i18next';

interface Props {
  action?: string;
  label?: string;
  name: string;
  type: 'text' | 'textarea' | 'number' | 'email' | 'password' | 'select' | 'switch' | 'file' | 'default';
  options?: any[];
  value?: any;
  placeholder?: string;
  setState?: Dispatch<SetStateAction<any>>;
  stateValue?: any;
  defaultValue?: any;
  onChange?: (newValue: any) => void;
  handleChange?: (type: string) => void;
  accept?: string;
}

const Input: React.FC<Props> = (
  {
    action,
    label,
    name,
    type,
    options,
    value,
    placeholder,
    setState,
    stateValue,
    defaultValue,
    onChange,
    handleChange,
    accept
  }) => {
  const [field, meta, { setValue }] = useField({
    name,
    type
  });
  const [showItem, setShowItem] = useState('');

  const fileInputRef = useRef<any>('');

  const renderField = (): React.ReactNode => {
    if (type === 'select') {
      if (!options) {
        return; // Return early if options are not provided
      }

      const setKey = (newValue: any): string => {
        if (newValue && options) {
          // Check if options is an array of objects with 'value' properties
          if (Array.isArray(options) && options.length > 0 && typeof options[0] === 'object' && 'value' in options[0]) {
            const defaultOption = options.find((option) => option.value === newValue);
            return defaultOption ? defaultOption.key : '';
            // eslint-disable-next-line @typescript-eslint/brace-style
          }
          // Check if options is an array of values
          else if (Array.isArray(options) && options.includes(newValue)) {
            return newValue;
          }
        }
        return '';
      };

      // Initialize selectedValue using defaultValue or an empty string
      const [selectedValue, setSelectedValue] = useState('');

      useEffect(() => {
        // Use the setKey function to determine the initial value
        if (defaultValue) {
          setSelectedValue(setKey(defaultValue));
        } else {
          setSelectedValue('');
        }
      }, [defaultValue]);

      const handleSelect = (key: any): void => {
        setValue(key);
        setSelectedValue(setKey(key));
        if (onChange) {
          onChange(key);
        }
        if (handleChange) {
          handleChange(key);
        }
      };

      if (type === 'select') {
        const menu = (
          <div className="dropdown-list">
            <Menu onSelect={({ key }) => {
              handleSelect(key);
            }}>
              {options.map((item, index) => {
                if (item?.disabled) {
                  return null;
                } else {
                  return (
                    <MenuItem
                      key={item.value || item}
                      className="cursor-pointer"
                      disabled={item?.disabled ?? false}
                    >
                      {item.key || item}
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </div>
        );

        return (
          <Dropdown
            trigger={['click']}
            overlay={menu}
            animation="slide-up"
          >
            <button
              type="button"
              className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
            >
              {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
              {selectedValue || `${t('Select')}...`}
              <Icon name="caret-down" />
            </button>
          </Dropdown>
        );
      }
    }

    if (type === 'switch') {
      return (
        <Switch
          checked={field.value}
          onClick={(state) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            setValue(state);
          }}
        />
      );
    }

    if (type === 'number') {
      return (
        <input
          name={name}
          type="float"
          id={name}
          className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
          placeholder={placeholder}
          defaultValue={defaultValue}
          min="1"
          max="100"
          step="1"
          onChange={(event) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            setValue(event.target.value);
          }}
        />
      );
    }

    if (type === 'text') {
      return (
        <input
          {...field}
          name={name}
          type={type}
          id={name}
          className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
          placeholder={placeholder}
          value={stateValue}
          defaultValue={defaultValue}
          onChange={(event) => {
            if (setState) {
              setState(event.target.value);
            }
            if (handleChange) {
              handleChange(event.target.value);
            }
          }}
        />
      );
    }

    if (type === 'file') {
      return (
        <>
          <input
            ref={fileInputRef}
            name={name}
            type={type}
            id={name}
            className="hidden transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
            accept={accept}
            onChange={(event) => {
              if (setState) {
                if (event.target.files) {
                  if (event.target.files.length !== 0) {
                    setState(event.target.files[0]);
                    fileInputRef.current.value = '';
                  }
                }
              }
            }}
          />
          <Button
            outline
            style={{ margin: 0 }}
            type="button"
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            {t('Choose File')}
          </Button>
        </>
      );
    }

    if (action === 'login') {
      return (
        <input
          {...field}
          name={name}
          type={type}
          id={name}
          className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
          placeholder={placeholder}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <textarea
          {...field}
          name={name}
          id={name}
          className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none p-3 rounded-full h-[52px] text-xs resize-none"
          placeholder={placeholder}
          value={stateValue}
          defaultValue={defaultValue}
          onChange={(event) => {
            if (setState) {
              setState(event.target.value);
            }
          }}
        />
      );
    }

    return (
      <input
        {...field}
        name={name}
        type={type}
        id={name}
        className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
        placeholder={placeholder}
      />
    );
  };

  return (
    <div className="flex flex-col gap-y-2 w-full">
      {label && (
        <label
          htmlFor={name}
          className="w-full text-xs"
        >
          {label}
        </label>
      )}
      {renderField()}
      {meta.touched && meta.error && (
        <p className="text-red-600 text-xs">{meta.error}</p>
      )}
    </div>
  );
};

export default Input;
