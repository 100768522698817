import React, { useState, useEffect } from 'react';
import Icon from '../../../ui/shared/Icon';
import { DataGrid, type GridInitialState, GridToolbar } from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Switch from '../../../ui/shared/Switch';
import Button from '../../../ui/shared/Button';
import { appState, loginAsChild, logoutChild, handleDataGridStateChange } from '../../../index';
import { openToast } from '../../../helpers/toast';
import { formatLoginResponse } from '../../Unauthorized/Login/Login';
import SearchButton from '../../../ui/shared/SearchButton';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});
// todo() refactor super admin dashboard and reseller dashboard components into a single component as a lot of
//  functionality is reused
const SuperAdmin: React.FC = () => {
  const [accounts, setAccounts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState<any>([]);

  const handleLoginAsChild = async (account: any): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/login-as-child`;
    try {
      const loginAsChildResponse = await axios.post(url, { account_id: account.id });
      loginAsChild(formatLoginResponse(loginAsChildResponse.data, 'en'));
    } catch (err) {
      console.log(err);
      openToast({ type: 'error', label: 'Account unauthorized', autoClose: 2000, theme: 'dark' });
    }
  };

  const getGeotabUser = async (id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/getGeotabUser/${id}`;
    try {
      const geotabUserResponse = await axios.get(url);
      console.log(geotabUserResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserAndAccounts = async (): Promise<void> => {
    setLoading(true);
    const accountsUrl = `${process.env.REACT_APP_API_URL}/api/accounts?include=accountSettings`;
    const userSettingsUrl = `${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${appState.userId}(eq)`;

    try {
      const [accountsResponse, userSettingsResponse] = await Promise.all([
        axios.get(accountsUrl),
        axios.get(userSettingsUrl)
      ]);

      const transformedAccounts = await transformData(accountsResponse.data.data, userSettingsResponse.data.data);
      setAccounts(transformedAccounts);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getFuelCardCount = async (): Promise<any> => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards-account-count`;
      const response = await axios.get(url);
      return response.data.data;
    } catch (error: any) {
      console.error(error);
    }
  };

  const transformData = async (accounts: any, userSettings: any): Promise<any> => {
    if (!accounts || !userSettings) {
      return [];
    }
    const fuelCardCount = await getFuelCardCount();
    return accounts.map((account: any) => (
      {
        id: account.id,
        accountName: account.name,
        pairType: account.account_settings?.preferred_match
          ? (String(account.account_settings.preferred_match.charAt(0).toUpperCase()) +
            String(account.account_settings.preferred_match.slice(1)))
          : '',
        unitOfMeasure: userSettings?.unit_of_measure
          ? (String(userSettings.unit_of_measure.charAt(0).toUpperCase()) +
            String(userSettings.unit_of_measure.slice(1)))
          : '',
        fixedIFTA: account.account_settings?.fix_ifta,
        useAnnotations: account.account_settings?.use_annotations,
        cards: fuelCardCount[account.id] ?? 0,
        primaryUser: account.primary_user_id,
        accountSettingsId: account?.account_settings?.id,
        billing: account.billing
      }
    ));
  };

  useEffect(() => {
    logoutChild();
    void getUserAndAccounts();
  }, []);

  useEffect(() => {
    let filteredAccounts = accounts;

    if (searchFieldValue) {
      filteredAccounts = accounts.filter((account: any) => account.accountName.toLowerCase().includes(searchFieldValue.toLowerCase()));
    }

    setFilteredAccounts(filteredAccounts);
  }, [searchFieldValue, accounts]);

  const handleSearchAccounts = (query: string): void => {
    setSearchFieldValue(query.trim());
  };

  const toggleBilling = async (newValue: boolean, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    const params: { billing: boolean, } = {
      billing: newValue
    };
    try {
      await axios.put(url, params);
    } catch (error: any) {
      console.error(error);
    }
  };

  const toggleFixIFTA = async (newValue: boolean, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings/${id}`;
    const params = {
      fix_ifta: newValue
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleUseAnnotations = async (newValue: boolean, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings/${id}`;
    const params = {
      use_annotations: newValue
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: 'accountName',
      headerName: t('Account Name'),
      width: 250
    },
    {
      field: 'billing',
      headerName: t('Billing'),
      width: 120,
      renderCell: (cell: any) => {
        return (
          <Switch
            key={'billing' + `${cell.row.id}`}
            checked={cell.row.billing}
            onClick={(newValue: boolean): void => {
              void toggleBilling(newValue, cell.row.id);
              setAccounts(accounts.map((currentAccount: any) => {
                if (currentAccount.id === cell.row.id) {
                  return {
                    ...currentAccount,
                    billing: newValue
                  };
                }
                return currentAccount;
              }));
            }}
          />
        );
      }
    },
    {
      field: 'fixedIFTA',
      headerName: t('Fix IFTA'),
      width: 120,
      renderCell: (cell: any) => {
        return (
          <Switch
            key={'fixedIFTA' + `${cell.row.id}`}
            checked={cell.row.fixedIFTA}
            onClick={(newValue: boolean) => {
              void toggleFixIFTA(newValue, cell.row.accountSettingsId);
              setAccounts(accounts.map((currentAccount: any) => {
                if (currentAccount.id === cell.row.id) {
                  return {
                    ...currentAccount,
                    fixedIFTA: newValue
                  };
                }
                return currentAccount;
              }));
            }}
          />
        );
      }
    },
    {
      field: 'useAnnotations',
      headerName: t('Use Annotations'),
      width: 150,
      renderCell: (cell: any) => {
        return (
          <Switch
            key={'useAnnotations' + `${cell.row.id}`}
            checked={cell.row.useAnnotations}
            onClick={(newValue: boolean) => {
              void toggleUseAnnotations(newValue, cell.row.accountSettingsId);
              setAccounts(accounts.map((currentAccount: any) => {
                if (currentAccount.id === cell.row.id) {
                  return {
                    ...currentAccount,
                    useAnnotations: newValue
                  };
                }
                return currentAccount;
              }));
            }}
          />
        );
      }
    },
    {
      field: 'cards',
      headerName: t('Cards'),
      width: 80
    },
    {
      field: 'action',
      headerName: '',
      width: 170,
      renderCell: (cell: any) => {
        return (
          <Button
            key={'action' + `${cell.row.id}`}
            outline
            variant="primary"
            type="button"
            className="!mt-0"
            onClick={() => {
              void handleLoginAsChild(cell.row).then();
            }}
          >
            {t('Login')}
          </Button>
        );
      }
    }
  ];

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center loading items-center bg-opacity-50 bg-black"
        >
          <CircularProgress/>
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="table-columns"/>
            <span>{t('System Admin Dashboard')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            <SearchButton
              onChange={handleSearchAccounts}
              isDefaultOpen={true}
            />
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <DataGrid
            onStateChange={(state: GridInitialState): void => {
              handleDataGridStateChange('superAdmin', state);
            }}
            initialState={appState.dataGridColumnState.value.superAdmin ?? {}}
            disableDensitySelector={true}
            hideFooter={true}
            localeText={{
              noRowsLabel: t('No rows'),
              toolbarColumns: t('Columns'),
              toolbarColumnsLabel: t('Columns'),
              toolbarDensity: t('Density'),
              toolbarDensityLabel: t('Density'),
              toolbarDensityCompact: t('Compact'),
              toolbarDensityStandard: t('Standard'),
              toolbarDensityComfortable: t('Comfortable'),
              toolbarQuickFilterPlaceholder: `${t('Search')}...`,
              MuiTablePagination: {
                labelRowsPerPage: t('Rows per Page'),
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
              }
            }}
            pagination
            rowCount={filteredAccounts.length}
            rows={[...filteredAccounts].sort((accountA: any, accountB: any) => {
              const keyA = (accountA.accountName || '').toLowerCase();
              const keyB = (accountB.accountName || '').toLowerCase();
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            })}
            paginationMode="server"
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            density="compact"
            slotProps={{
              toolbar: { // Disable Export Option
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            slots={{ toolbar: GridToolbar }}
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '50rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default withTranslation()(SuperAdmin);
