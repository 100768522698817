import React, { useState } from 'react';
import { store } from '../store';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import UnauthorizedLayout from '../layouts/Unauthorized';
import AuthorizedLayout from '../layouts/Authorized';
import Login from './Unauthorized/Login/Login';
import ForgotPassword from './Unauthorized/ForgotPassword/ForgotPassword';
import Transactions from './Authorized/Transactions/Transactions';
import MostExceptions from './Authorized/MostExceptions/MostExceptions';
import FillingInfo from './Authorized/FillingInfo/FillingInfo';
import FuelCards from './Authorized/FuelCards/FuelCards';
import DismissedTransactions from './Authorized/DismissedTransactions/DismissedTransactions';
import Reports from './Authorized/Reports/Reports';
import ResellerDashboard from './Authorized/Resellers/ResellerDashboard';
import Users from './Authorized/Users/Users';
import SystemAdmin from './Authorized/SystemAdmin/SystemAdmin';
import { AUTHORIZED_ROUTES, UNAUTHORIZED_ROUTES } from '../enums/ROUTES';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../ui/Page';
import Superadmin from './Authorized/Superadmin/Superadmin';
import NotFound from '../ui/NotFound';
import useUpdateAxiosAuthHeader from '../hooks/useUpdateAxiosAuthHeader';
import useiFrameMessages from '../hooks/useiFrameMessages';
import useFavicon from '../hooks/useFavicon';
import UnauthorizedAccess from './Unauthorized/UnauthorizedAccess/UnauthorizedAccess';
import { TransactionsContext } from '../contexts/transactionsContext';
import { type TransformedTransaction, type TransformedTransactionGroup } from '../types/transactions.d';
import UserAccess from './Unauthorized/UserAccess/UserAccess';
import Status from './Unauthorized/Status/Status';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<UnauthorizedLayout/>}>
        <Route
          path={UNAUTHORIZED_ROUTES.HOME}
          element={
            <Page
              noAnimation
              title="Login"
            >
              <Login/>
            </Page>}
        />
        <Route
          path={UNAUTHORIZED_ROUTES.FORGOT_PASSWORD}
          element={
            <Page
              noAnimation
              title="Forgot Password"
            >
              <ForgotPassword/>
            </Page>
          }
        />
        <Route
          path={UNAUTHORIZED_ROUTES.USER_ACCESS}
          element={
            <Page
              noAnimation
              title="Authenticate Geotab User"
            >
              <UserAccess />
            </Page>
          }
        />
        <Route
          path={UNAUTHORIZED_ROUTES.UNAUTHORIZED_ACCESS}
          element={
            <Page
              noAnimation
              title="Unauthorized Access"
            >
              <UnauthorizedAccess/>
            </Page>
          }
        />
        <Route
          path={UNAUTHORIZED_ROUTES.STATUS}
          element={
            <Page
              noAnimation
              title="Status"
            >
              <Status />
            </Page>
          }
        />
      </Route>
      <Route element={<AuthorizedLayout/>}>
        <Route
          path={AUTHORIZED_ROUTES.TRANSACTIONS}
          element={
            <Page title="Transactions">
              <Transactions/>
            </Page>
          }
        />
        <Route
          path={AUTHORIZED_ROUTES.MOST_EXCEPTIONS}
          element={
            <Page title="Most Exceptions">
              <MostExceptions/>
            </Page>
          }
        />

        <Route
          path={AUTHORIZED_ROUTES.FILLING_INFO}
          element={
            <Page title="Fueling Efficiency">
              <FillingInfo/>
            </Page>
          }
        />
        <Route
          path={AUTHORIZED_ROUTES.FUEL_CARDS}
          element={
            <Page title="Fuel Cards">
              <FuelCards/>
            </Page>
          }
        />
        <Route
          path={AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS}
          element={
            <Page title="Dismissed Transactions">
              <DismissedTransactions/>
            </Page>
          }
        />
        <Route
          path={AUTHORIZED_ROUTES.REPORTS}
          element={
            <Page title="Reports">
              <Reports/>
            </Page>
          }
        />
        <Route
          path={AUTHORIZED_ROUTES.RESELLERS}
          element={
            <Page title="Reseller Dashboard">
              <ResellerDashboard/>
            </Page>}
        />
        <Route
          path={AUTHORIZED_ROUTES.USERS}
          element={
            <Page title="Users">
              <Users/>
            </Page>}
        />
        <Route
          path={AUTHORIZED_ROUTES.SUPERADMIN}
          element={
            <Page title="Super Admin">
              <Superadmin/>
            </Page>
          }
        />
        <Route
          path={AUTHORIZED_ROUTES.SYSTEM_ADMIN}
          element={
            <Page title="System Admin">
              <SystemAdmin/>
            </Page>
          }
        />
        <Route
          path="*"
          element={<NotFound/>}
        />
      </Route>
    </>
  )
);

const App: React.FC = () => {
  useiFrameMessages();
  useUpdateAxiosAuthHeader();
  useFavicon();
  const [transactions, setTransactions] = useState<Array<TransformedTransaction | TransformedTransactionGroup>>([]);

  return (
    <>
      <ToastContainer/>
      <Provider store={store}>
        <TransactionsContext.Provider
          value={{
            transactions, setTransactions
          }}
        >
          <RouterProvider router={router}/>
        </TransactionsContext.Provider>
      </Provider>
    </>
  );
};

export default App;
