import React, { useEffect, useState } from 'react';
import { refreshLogin } from '../../../index';
import Button from '../../../ui/shared/Button';
import { CircularProgress } from '@mui/material';

const UnauthorizedAccess: React.FC = () => {
  const [retryFailed, setRetryFailed] = useState(false);
  const [isRetrying, setIsRetrying] = useState(true);

  useEffect(() => {
    const attemptRetry = async (): Promise<void> => {
      try {
        refreshLogin();
      } catch (err) {
        setRetryFailed(true);
      } finally {
        setIsRetrying(false);
      }
    };

    attemptRetry();
  }, []);

  return (
    <div className="w-full -my-4 mb-1 grid place-content-center place-items-center text-custom-gray-main">
      <p className="text-[4rem] leading-[8rem]">401</p>
      <p className="text-lg">You are not authorized to view this page</p>
      <div className="flex justify-center w-full">
        {isRetrying
          ? (
            <CircularProgress />
            )
          : retryFailed
            ? (
              <Button onClick={() => { refreshLogin(); }}>
                Retry
              </Button>
              )
            : null
        }
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
